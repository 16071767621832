export const notificationClickHandler = (message, navigation) => {
    const { action, loadId, module } = message?.data;
    switch (action) {
        case "OPEN_LOAD":
            if (loadId) {
                navigation("/exceptionDetails?id=" + loadId + "&module=" + module);
            }
            break;
        default:
            return;
    }
}