import BaseButton from "./BaseButton";
import { twMerge } from 'tailwind-merge'

interface FilledButtonProps {
    type?: "submit" | "reset",
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    text: string,
    className?: string,
    loading?: boolean
}

export default function FilledButton({ type, onClick, text, className, loading }: FilledButtonProps) {
    return (
        <BaseButton
            type={type}
            loading={loading}
            className={twMerge('bg-[#1A00E2] rounded-sm w-max px-2 p-0.5 text-white text-xs font-semibold uppercase', className || '')}
            onClick={onClick}
        >
            {text}
        </BaseButton>
    )
}
