import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useState, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge'

type DropDownProps = {
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
  width?: number;
  varient?: "rounded-full" | undefined;
  search?: boolean;
  value?: string;
  onChange?: (string) => void;
  onSearchTextChange?: (string) => void;
  initialSearchText?: string;
  optionTextClassName?: string;
};

const Dropdown = ({ label, options, width, varient, search, optionTextClassName,
  value, onChange, onSearchTextChange, initialSearchText }: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value || '');
  const [dropdownWidth, setDropdownWidth] = useState((width || 13) + 'rem');
  const [searchText, setSearchText] = useState(search ? initialSearchText || '' : '');
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  useEffect(() => {
    setSelected(value);
    setSearchText(initialSearchText || '');
  }, [value, initialSearchText]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getFilteredOptions = () => {
    const option = (options || []).find(o => o.label === searchText);
    if (option && selected) {
      return options;
    }
    return (options || []).filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (status: string) => {
    setSelected(status);
    const option = (options || []).find(o => o.value === status);
    option && setSearchText(option?.label);
    onChange && onChange(status);
  };

  const isSelected = (status: string) => selected === status;

  useEffect(() => {
    if (toggleRef.current) {
      setDropdownWidth(`${toggleRef.current.offsetWidth}px`);
    }
  }, [width]);

  return (
    <div className={`relative inline-block text-left`} style={{ minWidth: dropdownWidth }} ref={dropdownRef}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleToggle();
        }}
        className={`cursor-pointer bg-white flex items-center px-3 py-1 font-medium text-gray-700 focus:outline-none justify-around
          ${varient === "rounded-full" ? (isOpen ? "rounded-t-3xl" : "rounded-full") : ""} z-0`}
        ref={toggleRef}
        style={{ minWidth: dropdownWidth }}
      >
        <div className='basis-[80%]'>
          {
            search ?
              <input type="text" placeholder={label} className='w-full h-full outline-none min-w-[100%]' value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                  onSearchTextChange && onSearchTextChange(e.target.value)
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true)
                }
                } />
              : <span className="font-light w-full">{(selected ? (options || []).find(o => o.value === selected)?.label : label) || label}</span>
          }
        </div>
        <div className='basis-[10%]'>
          <span className="pl-3 w-full flex justify-end" >
            {isOpen ? (
              <ChevronUpIcon className="h-5 w-5 text-[#1C244A]" onClick={(e) => {
                if (!search) {
                  e.stopPropagation();
                }
                handleToggle()
              }} />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-[#1C244A]" onClick={(e) => {
                if (!search) {
                  e.stopPropagation();
                }
                handleToggle()
              }} />
            )}
          </span>
        </div>
      </div>
      {isOpen && (
        <div
          className={`origin-top-right absolute left-0 w-max shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          style={{ minWidth: dropdownWidth, maxWidth: '100%' }}
        >
          <div className="py-1" role="none">
            {(search ? getFilteredOptions() : options || []).map((option) => (
              <label
                key={option.value}
                className={`flex items-center px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 focus:outline-none ${isSelected(option.value) ? "bg-gray-100" : ""
                  } w-full`}
                role="menuitem"
              >
                <input
                  type="checkbox"
                  checked={isSelected(option.value)}
                  onChange={() => handleSelect(option.value)}
                  className="hidden"
                />
                <div className="w-4 h-4 p-0.5 border border-gray-300 rounded mr-2 bg-white min-w-4">
                  <div
                    className={`w-full h-full rounded-sm ${isSelected(option.value) ? "bg-activeTabHeadingsBorder" : "hidden"
                      }`}
                  />
                </div>
                <div className={twMerge('text-wrap break-words', optionTextClassName || '')}>
                  {option.label}
                </div>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;