import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { TruckIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../utils/errorhandling";
import { bucketDataAvailableTrucks } from "../../utils/availableTrucks";
import { fetchAvailableTrucksData } from "../../redux/slice/availableTrucks";

const AvailableTrucksCard = () => {
  //const { data, dataTS, loading, completedData, completedDataTS } = useAppSelector((state) => state.data);
  const { availableTrucks, loading } = useAppSelector((state) => state.availableTrucks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAvailableTrucksData()).then((actionResult) => {
      if (actionResult.type === "availableTrucksData/availableTrucksData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
    //dispatch(notesData());
  }, [dispatch]);

  const getData = (bucket) => {
    bucketDataAvailableTrucks([...availableTrucks])
    if (bucket === "UNASSIGNED") 
      return bucketDataAvailableTrucks([...availableTrucks]).UNASSIGNED;
    else if (bucket === "PAST")
      return bucketDataAvailableTrucks([...availableTrucks]).PAST;
    else if (bucket === "TODAY")
      return bucketDataAvailableTrucks([...availableTrucks]).TODAY;
    else if (bucket === "TOMORROW")
      return bucketDataAvailableTrucks([...availableTrucks]).TOMORROW;
    else
      return [...availableTrucks];
  }

  return loading ? (
    <Loading />
  ) : (
    <Card
      icon={TruckIcon}
      title="Available Trucks"
      tripData={{
        "Time of Last Trip": {
          "Before Today": getData("PAST").length,
          "Today": getData("TODAY").length,
          "Tomorrow Onwards": getData("TOMORROW").length,
          "All Trucks": getData("").length
        },
        "Other": {
          "Trucks with 0 Trips": getData("UNASSIGNED").length,
        },
      }}
      links={{
        "Time of Last Trip": {
          "Before Today": `/availableTrucksList?bucket=PAST`,
          "Today": `/availableTrucksList?bucket=TODAY`,
          "Tomorrow Onwards": `/availableTrucksList?bucket=TOMORROW`,
          "All Trucks": `/availableTrucksList`,
        },
        "Other": {
          "Trucks with 0 Trips": `/availableTrucksList?bucket=UNASSIGNED`,
        },
      }}
    />
  );
};

export default AvailableTrucksCard;
