import { CreatedFromConfig } from "../../types";
import { useNavigate } from "react-router-dom";

export default function ExceptionDetailsHeader({ curr_data, completed, taskConfig }: {
    curr_data: any[];
    completed?: boolean;
    taskConfig: CreatedFromConfig;
}) {
    const navigate = useNavigate();
    return (<div className="flex items-center justify-between">
        <div className="flex items-center my-4">
            <div className="mr-6 text-xl text-titleColor font-bold">
                Trip #{curr_data[0]?.trip_num}
            </div>
        </div>

        <div className="inline-flex gap-2">
            {completed && <div
                className="bg-[#4F5B67] rounded-sm w-max px-2 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase">
                Completed
            </div>}
            <button
            className={`${status ? "bg-[#B40808]" : "bg-[#1A00E2]"} align-middle inline-flex gap-2 rounded-sm w-max px-1 p-0.5 text-white text-xs font-semibold uppercase`}
            onClick={() => navigate(`/task/create?createdFromType=${taskConfig.createdFromType}&createdFromId=${taskConfig.createdFromId}`)}
        >
            Create Task
        </button>
        </div>
    </div>);
}