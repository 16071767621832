import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { getAuth, updateProfile } from 'firebase/auth';

export default function IntercomMessenger() {
    const { currentUser } = getAuth();
    initIntercom(currentUser);
    useEffect(() => {
        if (!currentUser) return;
        // updateProfile(currentUser, {
        //     displayName: "Utkarsh Mehta"
        // });
        initIntercom(currentUser);
    });

    return <></>;
}

function initIntercom(currentUser) {
    Intercom({
        app_id: 'foktpbvl',
        user_id: currentUser?.uid,
        name: currentUser?.displayName,
        email: currentUser?.email,
        created_at: +new Date(currentUser?.metadata?.creationTime),
    });
}
