import { TemperatureComplianceStatus, ETAStatus, ExceptionType, TripType, DetentionStatus, TripStatus } from "../types";

export type TripFilter = {
  tripType?: TripType;
  tripStatus?: TripStatus[];
  etaStatus?: ETAStatus[];
  tempStatus?: TemperatureComplianceStatus[];
  detentionStatus?: DetentionStatus[];
  // exceptionStatus?: ExceptionStatus[];
  completed?: boolean;
  dateFilter?: {
    fromDate?: number | undefined | null | "";
    toDate?: number | undefined | null | "";
  },
  module: ExceptionType;
  searchText?: string;
};

const useCompletedTrips = (filter: TripFilter): boolean => {
  return Boolean(filter?.completed);
}

const includeCompletedTrips = (filter: TripFilter): boolean => {
  return (filter?.tripStatus && filter?.tripStatus.includes(TripStatus.Completed)) ||
    (filter?.detentionStatus && filter.detentionStatus.includes(DetentionStatus.CompletedTripWithDetention)) ||
    (filter?.detentionStatus && filter.detentionStatus.includes(DetentionStatus.CompletedTripWithLoss));
}

export const getFilteredData = (data: any[], completedData: any[], filter: TripFilter) => {
  let filteredData = useCompletedTrips(filter) ? completedData : data;
  const _includeCompletedTrips = includeCompletedTrips(filter);
  if (!useCompletedTrips(filter) && _includeCompletedTrips) {
    filteredData = ([...filteredData, ...completedData]);
  }
  if (filter?.tripType) {
    if (filter?.tripType === "internal") {
      filteredData = filteredData?.filter((d) => Boolean(d?.driver?.samsara_id));
    } else if (filter?.tripType === "external") {
      filteredData = filteredData?.filter((d) => !Boolean(d?.driver?.samsara_id));
    }
  }
  // Exception status filter logic
  // filteredData = applyExceptionStatusFilter(filteredData, filter);
  // TripStatus filter logic
  filteredData = applyTripStatusFilter(filteredData, filter);
  // ETA filter logic
  filteredData = applyETAStatusFilter(filteredData, filter);
  // Temp monitoring filter logic
  filteredData = applyTemperatureComplianceFilter(filteredData, filter);
  // Detention filter logic
  filteredData = applyDetentionFilter(filteredData, filter);
  // Module specific filter logic
  filteredData = applyModuleSpecificFilters(filteredData, filter);
  if (filter?.dateFilter) {
    if (filter.dateFilter?.fromDate && filter.dateFilter?.toDate) {
      filteredData = filteredData?.filter((d) => {
        const dt = getDateForFilter(d);
        if (!dt) {
          return false;
        }
        return dt >= +new Date(filter.dateFilter.fromDate) && dt <= +new Date(filter.dateFilter.toDate);
      });
    }
  }
  if (filter?.searchText) {
    filteredData = filteredData?.filter((d) => {
      if (!d?.trip_num) {
        return false;
      }
      return d?.trip_num.toLowerCase().includes(filter.searchText.toLowerCase());
    });
  }
  return filteredData;
};

function getDateForFilter(trip: any) {
  if (trip.deliver_date) {
    return +new Date(trip.deliver_date);
  } else {
    if (trip?.stops?.length) {
      return +new Date(trip?.stops[trip.stops.length - 1]?.deliver_date);
    } else {
      return null;
    }
  }
}

const applyModuleSpecificFilters = (data: any[], filter: TripFilter) => {
  switch (filter.module) {
    case ExceptionType.ETA:
      return data;
    case ExceptionType.TEMP_VIOLATION:
      return data;
    case ExceptionType.DETENTION:
      return data.filter(d => {return (d?.detention_summary?.detention_total_amount || d?.detention_summary?.loss_total_amount)});
    default:
      return data;
  }
}

// const applyExceptionStatusFilter = (data: any[], filter: TripFilter) => {
//   if (!filter?.exceptionStatus || !filter?.exceptionStatus?.length) {
//     return data;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.All)) {
//     return data;
//   }
//   return data.filter(d => {
//     switch (filter.module) {
//       case ExceptionType.ETA:
//         return applyETAExceptionStatus(d, filter);
//       case ExceptionType.TEMP_VIOLATION:
//         return applyTempExceptionStatus(d, filter);
//       case ExceptionType.DETENTION:
//         return applyDetentionExceptionStatus(d, filter);
//     }
//   });
// }

// const applyETAExceptionStatus = (data: any, filter: TripFilter): boolean => {
//   if (filter.exceptionStatus.includes(ExceptionStatus.Open)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "ETA" && e?.exception_status !== "ON_TIME").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.Closed)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "ETA" && e?.exception_status === "ON_TIME").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.InProgress)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "ETA" && e?.exception_status === "LATE").length > 0;
//   }
//   return false;
// }

// const applyTempExceptionStatus = (data: any, filter: TripFilter): boolean => {
//   if (filter.exceptionStatus.includes(ExceptionStatus.Open)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "TEMPERATURE" && e?.exception_status !== "IN_RANGE").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.Closed)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "TEMPERATURE" && e?.exception_status === "IN_RANGE").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.InProgress)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "TEMPERATURE" && e?.exception_status !== "IN_RANGE").length > 0;
//   }
//   return false;
// }

// const applyDetentionExceptionStatus = (data: any, filter: TripFilter): boolean => {
//   if (filter.exceptionStatus.includes(ExceptionStatus.Open)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "DETENTION" && e?.exception_status !== "NONE").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.Closed)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "DETENTION" && e?.exception_status === "NONE").length > 0;
//   }
//   if (filter.exceptionStatus.includes(ExceptionStatus.InProgress)) {
//     return (data?.exception_status_tracker?.current_exceptions || []).filter(e => e?.exception_type === "DETENTION" && e?.exception_status !== "NONE").length > 0;
//   }
//   return false;
// }

const applyTripStatusFilter = (data: any[], filter: TripFilter) => {
  if (!filter?.tripStatus || !filter?.tripStatus?.length) {
    return data;
  }
  if (filter.tripStatus.includes(TripStatus.All)) {
    return data;
  }
  return data.filter(d => {
    if (filter.tripStatus.includes(TripStatus.InTransit) && d.trip_status === "In Transit") {
      return true;
    }
    if (filter.tripStatus.includes(TripStatus.Dispatched) && d.trip_status === "Dispatched") {
      return true;
    }
    if (filter.tripStatus.includes(TripStatus.Completed) && d.trip_status === "Delivered") {
      return true;
    }
  });
}

const applyETAStatusFilter = (data: any[], filter: TripFilter) => {
  if (!filter.etaStatus || filter.etaStatus.length === 0) {
    return data;
  }

  if (filter.etaStatus.includes(ETAStatus.All)) {
    return data;
  }

  return data.filter((d) => {
    if (filter.etaStatus.includes(ETAStatus.OnTime) && !isEtaException(d)) {
      return true;
    }
    if (filter.etaStatus.includes(ETAStatus.Late) && isEtaException(d)) {
      return true;
    }
    if (filter.etaStatus.includes(ETAStatus.OnTimePreviouslyLate) && !isEtaException(d) && isPreviouslyLate(d)) {
      return true;
    }
    return false;
  });
};

// Helper function to determine if a trip was previously late but now on time
const isPreviouslyLate = (trip: any) => {
  const history = trip?.exception_status_tracker?.exception_history || [];
  const etaHistory = history.filter(h => h?.exception_type === "ETA");
  if (etaHistory.length === 0) {
    return false;
  } else {
    return etaHistory.filter(h => h?.exception_status === "LATE").length > 0;
  }
};

export const isTripException = (trip: any, module: string): boolean => {
  switch (module) {
    case ExceptionType.ETA:
      return isEtaException(trip);
    case ExceptionType.TEMP_VIOLATION:
      return isTemperatureViolation(trip);
    case ExceptionType.DETENTION:
      return isDetentionViolation(trip);
    default:
      return false;
  }
}

export const isTemperatureViolation = (trip: any) => {
  if (trip?.temperature_monitoring_details) {
    if (typeof trip?.temperature_monitoring_details === "object") {
      if (Object.keys(trip?.temperature_monitoring_details).length === 0) {
        return false;
      } else {
        if (isNullOrUndefined(trip?.temperature_monitoring_details?.temperature_exception_status)) {
          return false;
        } else {
          return trip?.temperature_monitoring_details?.temperature_exception_status;
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
}

export const isExternalDriver = (driver: any) => {
  return !Boolean(driver?.samsara_id)
}

export function isDetentionViolation(d: any): boolean {
  return d?.detention_summary?.is_detention || d?.detention_summary?.is_loss;
}

export function isEtaException(d: any): boolean {
  return Boolean(d?.eta_details?.is_late);
}

const applyTemperatureComplianceFilter = (data: any[], filter: TripFilter) => {
  if (!filter.tempStatus || filter.tempStatus.length === 0) {
    return data;
  }

  if (filter.tempStatus.includes(TemperatureComplianceStatus.All)) {
    return data;
  }

  return data.filter((d) => {
    const hasTempViolation = isTemperatureViolation(d);
    if (filter.tempStatus.includes(TemperatureComplianceStatus.Compliant) && !hasTempViolation) {
      return true;
    }
    if (filter.tempStatus.includes(TemperatureComplianceStatus.InViolation) && hasTempViolation) {
      return true;
    }
    if (filter.tempStatus.includes(TemperatureComplianceStatus.CompliantPreviouslyInViolation) && !hasTempViolation && isPreviouslyInViolation(d)) {
      return true;
    }
    return false;
  });
};

const isPreviouslyInViolation = (trip: any) => {
  const history = trip?.exception_status_tracker?.exception_history || [];
  const tempHistory = history.filter(h => h?.exception_type === "TEMPERATURE");
  if (tempHistory.length === 0) {
    return false;
  } else {
    return tempHistory.filter(h => h?.exception_status !== "IN_RANGE").length > 0;
  }
}

const applyDetentionFilter = (data: any[], filter: TripFilter) => {
  if (!filter.detentionStatus || filter.detentionStatus.length === 0) {
    return data;
  }
  if (filter.detentionStatus.includes(DetentionStatus.All)) {
    return data;
  }
  return data.filter((d) => {
    if (filter.detentionStatus.includes(DetentionStatus.PreDetention) &&
      (d?.detention_summary?.current_state === "PRE_DETENTION" ||
        d?.detention_summary?.current_state === "PRE_LOSS") &&
      !(d?.detention_summary?.current_state === "NONE" && d?.detention_summary?.status === "OPEN")) {
      return true;
    }
    if (filter.detentionStatus.includes(DetentionStatus.InDetention) &&
      (d?.detention_summary?.current_state === "IN_DETENTION" ||
        d?.detention_summary?.current_state === "IN_LOSS") &&
      !(d?.detention_summary?.current_state === "NONE" && d?.detention_summary?.status === "OPEN")) {
      return true;
    }
    if (filter.detentionStatus.includes(DetentionStatus.CompletedTripWithDetention) &&
      d?.detention_summary?.current_state === "NONE" &&
      d?.detention_summary?.status === "CLOSED" &&
      d?.detention_summary?.is_detention) {
      return true;
    }
    if (filter.detentionStatus.includes(DetentionStatus.CompletedTripWithLoss) &&
      d?.detention_summary?.current_state === "NONE" &&
      d?.detention_summary?.status === "CLOSED" &&
      d?.detention_summary?.is_loss) {
      return true;
    }
  });
};
