import { getDateFromDatePicker } from "../../utils/dateutils";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
    label?: string;
    placeholder: string;
    onChange: (from: Date, to: Date) => void;
    value: string;
    fromDate?: Date | undefined;
    toDate?: Date | undefined;
    singleDate?: boolean;
    className?: {
        label?: string;
        parent?: string;
    } | undefined;
    allowinput?: boolean;
    inputFormat?: undefined | "MM/DD/YYYY";
    initialSearchText?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({ label, placeholder, onChange, value, fromDate: from, toDate: to,
    singleDate, className, allowinput, inputFormat, initialSearchText }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [fromDate, setFromDate] = useState<Date | null>(singleDate ? new Date(value) : (from || null));
    const [toDate, setToDate] = useState<Date | null>(to || null);
    const [inputDate, setInputDate] = useState<string | undefined>(initialSearchText || '');
    const dropdownRef = useRef(null);
    const options = ["Select", "Today", "This Week", "This Month", "This Year"];

    useEffect(() => {
        setInputDate(initialSearchText || '');
        setFromDate(singleDate ? new Date(value) : (from || null));
        setToDate(singleDate ? new Date(value) : (to || null)); 
    }, [initialSearchText, value, singleDate, from, to]);

    const formatDate = (date: Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        if (inputFormat === "MM/DD/YYYY") {
            return `${month}/${day}/${year}`;
        }
        return `${day}/${month}/${year}`;
    }

    const handleOptionSelect = (option: string) => {
        const today = new Date();
        let fromDate, toDate;

        switch (option) {
            case "Today":
                fromDate = today;
                toDate = today;
                break;
            case "This Week":
                const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
                fromDate = startOfWeek;
                toDate = endOfWeek;
                break;
            case "This Month":
                const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                fromDate = startOfMonth;
                toDate = endOfMonth;
                break;
            case "This Year":
                const startOfYear = new Date(today.getFullYear(), 0, 1);
                const endOfYear = new Date(today.getFullYear(), 11, 31);
                fromDate = startOfYear;
                toDate = endOfYear;
                break;
            default:
                fromDate = null;
                toDate = null;
                break;
        }

        setFromDate(fromDate);
        setToDate(toDate);
        onChange(fromDate, toDate);
        setIsOpen(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (inputFormat === "MM/DD/YYYY") {
            const date = new Date(value);
            if (date.toString() !== "Invalid Date") {
                setFromDate(date);
                setToDate(date);
                onChange(date, date);
            }
        }
        setInputDate(value);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleToggle = (status?: boolean) => {
        if (status !== undefined) {
            setIsOpen(status);
            return;
        }
        setIsOpen(!isOpen);
    };

    const isSelected = (status: string) => selectedOption === status;

    return (
        <div className={twMerge(`relative inline-block text-left text-sm ${isOpen ? " min-w-[15.2rem]" : ""}`, className?.parent || "")} ref={dropdownRef}>
            {label && <label className="block text-black font-semibold mb-2">{label}</label>}
            <div
                onClick={() => handleToggle(true)}
                className={`cursor-pointer inline-flex items-center justify-between px-4 py-1 ${isOpen ? "rounded-t-3xl" : "rounded-full"
                    } border bg-white font-medium text-gray-700 focus:outline-none w-full min-w-52 relative`}
            >
                <div className="flex-grow text-left">
                    {allowinput && singleDate ?
                        <input className="w-full outline-none" placeholder={placeholder} onChange={handleInputChange} value={inputDate} /> :
                        <span className="flex-grow text-center">
                            {placeholder || value || "Select"}
                        </span>
                    }
                </div>
                <span className="ml-auto flex-shrink-0" onClick={(e) => {
                    e.stopPropagation();
                    handleToggle()
                }}>
                    {isOpen ? (
                        <ChevronUpIcon className="h-5 w-5" />
                    ) : (
                        <ChevronDownIcon className="h-5 w-5" />
                    )}
                </span>
            </div>

            {isOpen && (
                <div
                    className={`absolute top-100 left-0 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${singleDate ? "" : "pt-1"} overflow-y-scroll`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {(singleDate ? [] : options).map((option) => (
                        <label
                            key={option}
                            className={`flex items-center px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 focus:outline-none ${isSelected(option) ? "bg-gray-100" : ""
                                }`}
                            role="menuitem"
                            onClick={() => {
                                setSelectedOption(option);
                                handleOptionSelect(option);
                                setIsOpen(false);
                            }}
                        >
                            {option}
                        </label>
                    ))}
                    {!singleDate && <div className="flex flex-col">
                        <label
                            className={`flex items-center px-4 py-2 font-medium text-gray-900 focus:outline-none`}
                            role="menuitem"
                        >
                            Custom Date Range
                        </label>
                        <div className="flex flex-row my-auto gap-2 align-middle justify-start px-2 py-2">
                            <input disabled type="text" className="border border-gray-300 rounded-sm p-1 w-24" placeholder="From" value={getDateFromDatePicker(fromDate)} />
                            <span className="text-center my-auto">to</span>
                            <input disabled type="text" className="border border-gray-300 rounded-sm p-1 w-24" placeholder="To" value={getDateFromDatePicker(toDate)} />
                        </div>
                    </div>}
                    <div className="flex justify-start m-auto">
                        {singleDate ?
                            <ReactDatePicker
                                selected={fromDate}
                                onSelect={(date) => {
                                    setFromDate(date);
                                    setToDate(date);
                                    onChange(date, date);
                                    if (allowinput && date) {
                                        setInputDate(formatDate(date));
                                    }
                                }}
                                inline
                            />
                            : <ReactDatePicker
                                selectsRange
                                onChange={(date) => {
                                    if (date[0]) {
                                        setFromDate(date[0]);
                                        setToDate(null);
                                    }
                                    if (date[1]) {
                                        setToDate(date[1]);
                                    }
                                    if (date[0] && date[1]) {
                                        onChange(date[0], date[1]);
                                        setIsOpen(false);
                                    }
                                }}
                                inline
                                startDate={fromDate}
                                endDate={toDate}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePicker;