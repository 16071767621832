import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData } from "../../redux/slice/data";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorhandling";
import { ETAStatus, ExceptionType } from "../../types";

const TripCard = () => {
  const { data, completedData, dataTS, loading } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.length > 0 && (dataTS + (10 * 60 * 1000)) > Date.now()) return;
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        if (actionResult.payload?.action !== "refreshToken") {
          if (actionResult.payload?.message) {
            handleError(actionResult.payload, toast, actionResult.payload?.message);
          }
        }
      }
    });
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      title="Trip ETA"
      skipCount = {["Completed"]}
      tripData={{
        "Company Driver Trips": {
          Late: getFilteredData(data, completedData, { tripType: "internal", etaStatus: [ETAStatus.Late], module: ExceptionType.ETA }).length,
          "On Time": getFilteredData(data, completedData, { tripType: "internal", etaStatus: [ETAStatus.OnTime], module: ExceptionType.ETA }).length,
          Completed: getFilteredData(data, completedData, { tripType: "internal",  completed: true, module: ExceptionType.ETA }).length,
        },
        "External Driver Trips": {
          Late: getFilteredData(data, completedData, { tripType: "external", etaStatus: [ETAStatus.Late], module: ExceptionType.ETA }).length,
          "On Time": getFilteredData(data, completedData, { tripType: "external", etaStatus: [ETAStatus.OnTime], module: ExceptionType.ETA }).length,
          Completed: getFilteredData(data, completedData, { tripType: "external",  completed: true, module: ExceptionType.ETA }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          Late: `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            etaStatus: [ETAStatus.Late],
            module: "eta",
          })}`,
          "On Time": `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            etaStatus: [ETAStatus.OnTime],
            module: "eta",
          })}`,
          Completed: `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            completed: true,
            module: "eta",
          })}`,
        },
        "External Driver Trips": {
          Late: `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            etaStatus: [ETAStatus.Late],
            module: "eta",
          })}`,
          "On Time": `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            etaStatus: [ETAStatus.OnTime],
            module: "eta",
          })}`,
          Completed: `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            completed: true,
            module: "eta",
          })}`,
        },
      }}
    />
  );
};

export default TripCard;
