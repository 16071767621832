import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorhandling";
import { PlusIcon } from "@heroicons/react/24/outline";
import { fetchAvailableTrucksData } from "../../redux/slice/availableTrucks";

const AvailableTrucksCard = () => {
    //const { data, dataTS, loading, completedData, completedDataTS } = useAppSelector((state) => state.data);
    const { availableTrucks, loading } = useAppSelector((state) => state.availableTrucks);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAvailableTrucksData()).then((actionResult) => {
            if (actionResult.type === "availableTrucksData/availableTrucksData/rejected") {
                handleError(actionResult.payload, toast, actionResult.payload);
            }
        });
    }, [dispatch]);

    return loading ? (
        <Loading />
    ) : (
        <Card
            image={<img src={require("../../../assets/images/exceptionCards/customException.png")} alt="Custom_exception" className="w-5 h-5" />}
            title="Custom Exceptions"
            className="h-fit"
        >
            <div className="flex flex-col">
                <span className="p-1 text-gray-700 font-medium">Add a custom exception if it does not fit an existing exception type</span>
                <div className="flex justify-center">
                    <div className="bg-blue-600 text-white rounded-md px-2 m-auto font-extrabold text-3xl">
                        <span>+</span>
                    </div>
                </div>
            </div>

        </Card>
    );
};

export default AvailableTrucksCard;
