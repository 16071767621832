import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { withAuthHeader } from "../../utils/api";

export const autocomplete = createAsyncThunk(
  "customerNames/autocomplete",
  async (params: {
    search?: string;
    limit?: number;
  }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER + `/v1/loads/customer-name/autocomplete?search=${params.search || ""}&limit=${params.limit}`,
        {
          mode: "cors",
          method: "GET",
          headers: await withAuthHeader({
            Accept: "application/json",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data?.Data || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for the data with reducers to handle the actions
export const customerNameSlice = createSlice({
  name: "customerNamesData",
  initialState: {
    customerNames: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(autocomplete.pending, (state) => {
        state.loading = true;
      })
      .addCase(autocomplete.fulfilled, (state, action) => {
        state.loading = false;
        state.customerNames = action.payload;
      })
      .addCase(autocomplete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
