import React from "react";
import { useState, useEffect } from "react";
import NoteItem from "../Notes/NoteItem";
import { toast } from "react-toastify";
import CreateNote from "../Notes/CreateNote";
import { getNotes } from "../../redux/slice/notes";

export default function ExceptionNotesSection(props: { originId: string, originType: string, notesUpdated?: () => void, exceptionType?: "DETENTION" | "ETA" | "TEMP_MONITORING" }) {
  if (!props?.originId) {
    return null;
  }
  const [showSpinner, setShowSpinner] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [showAddNote, setShowAddNote] = useState(false);

  useEffect(() => {
    if (props?.originId && props?.originId != "undefined") {
      getNotes(props.originId, props.originType, toast).then((data) => {
        setNotes(data);
        setShowSpinner(false);
      });
    }
  }, [props?.originId]);

  const handleAddNoteClick = () => {
    setShowAddNote(true);
  };

  const filterNotes = (): any[] => {
    return notes.filter((item) => {
      if (item.type === "USER_GENRATED") {
        return true;
      } else {
        if (item?.additional_metadata?.exception_type && props.exceptionType) {
          return item?.additional_metadata?.exception_type === props.exceptionType;
        } else {
          return true;
        }
      }
    });
  }

  return (
    <>
      <div className="w-full">
        <div className="float-right p-4 rounded mb-4 mt-2 text-sm font-medium">
          {showSpinner && (
            <i className="animate-spin fa-solid fa-spinner"></i>
          )}
          <button className="pl-4" onClick={handleAddNoteClick}>
            Add New Note <i className="pl-1 fa-solid fa-square-plus"></i>
          </button>
        </div>
      </div>
      <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4 w-full">
        {showAddNote && <CreateNote key="create-note"
          originId={props.originId} originType={props.originType} noteSaved={reloadNotes} />}
        {filterNotes().map((item, index) => (
          <NoteItem
            noteId={item._id}
            key={index}
            createTime={item.created_at}
            note={item.note}
            type={item.type || "User"}
            createdBy={item.created_by?.name}
            uploads={item.uploads || []}
            reloadNotes={reloadNotes}
          />
        ))}
      </div>
    </>
  );

  async function reloadNotes() {
    setShowAddNote(false);
    setNotes([]);
    const data = await getNotes(props.originId, props.originType, toast);
    setNotes(data);
    setShowSpinner(false);
    props.notesUpdated && props.notesUpdated();
  }
}
