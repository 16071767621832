import { getToken, isTokenExpired, refreshUserToken, signOut } from "../firebase/config";

export const handleErrorAction = async (error) => {
    if (error.action) {
        switch (error.action) {
            case "refreshToken":
                await refreshUserToken();
                break;
            case "forceLogout":
                await signOut();
                break;
            default:
                console.error(error);
                break;
        }
    }
};

type RequestHeaders = {
    [key: string]: string;
};

export const withAuthHeader = async (
    headers: RequestHeaders
): Promise<RequestHeaders> => {
    let token = await getToken();
    if (!token) {
        return headers;
    } else {
        const tokenExpired = await isTokenExpired();
        if (tokenExpired) {
            token = await refreshUserToken();
            return {
                ...headers,
                Authorization: "Bearer " + token,
            };
        } else {
            return {
                ...headers,
                Authorization: "Bearer " + token,
            };
        }
    }
};