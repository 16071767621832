import BaseButton from "./BaseButton";
import { twMerge } from 'tailwind-merge'

export default function OutlinedButton({ type, onClick, text, className }: { type?: "submit" | "reset", onClick: () => void, text: string, className?: string }) {
    return (
        <BaseButton
            type={type}
            className={twMerge('bg-transparent border border-[#1A00E2] rounded-sm w-max px-2 p-0.5 text-[#1A00E2] text-xs font-semibold uppercase', className || '')}
            onClick={onClick}
        >
            {text}
        </BaseButton>
    )
}
