import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducer/index';

const store = configureStore({
    reducer: rootReducer
});

export default store;

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
