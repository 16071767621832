import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrorAction, withAuthHeader } from "../../utils/api";
import { CreateTaskSchema } from "../../types";

export const fetchTaskTypes = createAsyncThunk(
  "manualTaskData/fetchTaskTypes",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/tasks/task-type",
          {
            mode: "cors",
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const fetchTaskDepartments = createAsyncThunk(
  "manualTaskData/fetchTaskDepartments",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/tasks/department",
          {
            mode: "cors",
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const fetchTasks = createAsyncThunk(
  "manualTaskData/fetchTasks",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/tasks/task/all",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({}),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const createTask = createAsyncThunk(
  "manualTaskData/createTask",
  async (task: CreateTaskSchema, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/tasks/task",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              department_id: task.departmentId,
              task_type_id: task.taskTypeId,
              trip_id: task.tripId,
              truck_id: task.truckId,
              trailer_id: task.trailerId,
              driver_id: task.driverId,
              issue: {
                instruction: task.instruction,
                description: task.description,
              },
              order_id: task.orderNumber,
              status: task.status || "OPEN",
              customer_name: task.customerName,
              due_date: task.dueDate,
              assigned_to: {
                user_id: task.assignedTo.email,
                name: task.assignedTo.name,
              },
              origin: {
                ...task.origin,
                meta_data: task?.origin?.meta_data || {},
              }
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const updateTask = createAsyncThunk(
  "manualTaskData/updateTask",
  async (task: CreateTaskSchema & {
    taskId: string;
  }, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/tasks/task",
          {
            mode: "cors",
            method: "PUT",
            headers,
            body: JSON.stringify({
              task_id: task.taskId,
              department_id: task.departmentId,
              task_type_id: task.taskTypeId,
              trip_id: task.tripId,
              truck_id: task.truckId,
              trailer_id: task.trailerId,
              driver_id: task.driverId,
              issue: {
                instruction: task.instruction,
                description: task.description,
              },
              order_id: task.orderNumber,
              status: task.status || "OPEN",
              customer_name: task.customerName,
              due_date: task.dueDate,
              assigned_to: {
                user_id: task.assignedTo.email,
                name: task.assignedTo.name,
              },
              origin: {
                ...task.origin,
                meta_data: task?.origin?.meta_data || {},
              }
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const manualTasksSlice = createSlice({
  name: "manualTasksData",
  initialState: {
    taskTypes: [],
    departments: [],
    tasks: [],
    loading: {
      updateTask: false,
      getTaskTypes: false,
      getDepartments: false,
      createTask: false,
      fetchTasks: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskTypes.pending, (state) => {
        state.loading.getTaskTypes = true;
      })
      .addCase(fetchTaskTypes.fulfilled, (state, action) => {
        state.loading.getTaskTypes = false;
        state.taskTypes = action.payload;
      })
      .addCase(fetchTaskTypes.rejected, (state, action) => {
        state.loading.getTaskTypes = false;
        state.error = action.payload;
      })
      .addCase(fetchTaskDepartments.pending, (state) => {
        state.loading.getDepartments = true;
      })
      .addCase(fetchTaskDepartments.fulfilled, (state, action) => {
        state.loading.getDepartments = false;
        state.departments = action.payload;
      })
      .addCase(fetchTaskDepartments.rejected, (state, action) => {
        state.loading.getDepartments = false;
        state.error = action.payload;
      })
      .addCase(createTask.pending, (state) => {
        state.loading.createTask = true;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.loading.createTask = false;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.loading.createTask = false;
        state.error = action.payload;
      })
      .addCase(updateTask.pending, (state) => {
        state.loading.updateTask = true;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.loading.updateTask = false;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.loading.updateTask = false;
        state.error = action.payload;
      })
      .addCase(fetchTasks.pending, (state) => {
        state.loading.createTask = true;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.loading.createTask = false;
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading.createTask = false;
        state.error = action.payload;
      });
  },
});
