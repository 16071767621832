export const copyInnerText = (id: string, toast?: any) => {
    const el = document.getElementById(id);
    var textToCopy = el.innerText;
    // Use the Clipboard API to copy the text
    navigator.clipboard.writeText(textToCopy).then(function () {
        toast?.info("Copied", {
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: false,
        });
    }).catch(function (err) {
        console.error("Could not copy text: ", err);
    });
}