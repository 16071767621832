import { isNullOrUndefined } from "./triputils";

export function displayTime(eta) {
  return eta
    ? new Date(eta).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    })
    : "";
}

export function displayDate(eta) {
  return eta
    ? new Date(eta).toLocaleDateString("en-US", {
      timeZone: "UTC",
    })
    : "";
}

export function displayShortDate(date) {
  return date
    ? new Date(date).toLocaleDateString("en-US", {
      timeZone: "UTC",
      day: "2-digit",
      month: "2-digit",
    })
    : "";
}

export function displayTimeDetails(timeDetails) {
  if (
    !timeDetails ||
    timeDetails.hours == undefined ||
    !timeDetails.minutes == undefined
  ) {
    return "";
  }

  return (
    (timeDetails.hours >= 10 ? "" : "0") +
    timeDetails.hours.toString() +
    ":" +
    (timeDetails.minutes >= 10 ? "" : "0") +
    timeDetails.minutes.toString()
  );
}

export function getCurrentLocalTimeWithOffset(offset) {
  let utcDate = new Date();
  let offsetMs = offset * 60 * 1000;
  let localDate = new Date(utcDate.getTime() + offsetMs);

  return localDate;
}

export function beautifyTime(seconds?: number, fallback?: string, format = {
  minuteUnit: "m",
  hourUnit: "h",
}) {
  if (isNullOrUndefined(seconds)) {
    return fallback || "";
  } else if (seconds === 0) {
    return `0 ${getSingularUnit(format.hourUnit)} 0 ${getSingularUnit(format.minuteUnit)}`;
  }

  if (seconds < 0) {
    return fallback || "";
  }

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);

  if (hours === 1 && format.hourUnit === "hours") {
    format.hourUnit = getSingularUnit(format.hourUnit);
  }
  if (minutes === 1 && format.minuteUnit === "mins") {
    format.minuteUnit = getSingularUnit(format.minuteUnit);
  }

  return (
    (hours > 0 ? hours + ` ${format.hourUnit} ` : "") + (minutes > 0 ? minutes + ` ${format.minuteUnit}` : "")
  );
}

function getSingularUnit(unit: string) {
  switch (unit) {
    case "hours":
      return "hour";
    case "mins":
      return "min";
    case "hrs":
      return "hr";
    case "minutes":
      return "minute";
    default:
      return unit;
  }
}

export function formatDateTimeString(utcTime, offset?: number, fallback?: string, format = {
  time: true,
  date: true
}) {
  if (!utcTime) {
    return fallback || "";
  }
  const date = new Date(utcTime);

  const adjustedTime = new Date(+date + ((offset || 0) * 60 * 1000));

  // Format the time
  const hours = adjustedTime.getUTCHours();
  const minutes = adjustedTime.getUTCMinutes();
  const day = String(adjustedTime.getUTCDate()).padStart(2, '0');
  const month = String(adjustedTime.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = adjustedTime.getUTCFullYear();

  // Convert hours to 12-hour format
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHour = hours % 12 || 12; // Convert 0 hours to 12 for midnight

  // Format minutes with leading zero if necessary
  const formattedMinutes = String(minutes).padStart(2, '0');

  if (format.time && !format.date) {
    return `${formattedHour}:${formattedMinutes} ${period}`;
  } else if (!format.time && format.date) {
    return `${day}/${month}/${year}`;
  } else if (!format.time && !format.date) {
    return "";
  } else {

    // Construct the final formatted time string
    const formattedTime = `${formattedHour}:${formattedMinutes} ${period} ${month}/${day}/${year}`;

    return formattedTime;
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function displayTimeWithOffset(dt, offset) {
  const date = new Date(dt);
  const adjustedTime = new Date(+date + ((offset || 0) * 60 * 1000));
  return adjustedTime && isValidDate(adjustedTime)
    ? new Date(adjustedTime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    })
    : "";
}

export function displayDateWithOffset(dt, offset) {
  const date = new Date(dt);
  const adjustedTime = new Date(+date + ((offset || 0) * 60 * 1000));
  return adjustedTime && isValidDate(adjustedTime)
    ? new Date(adjustedTime).toLocaleDateString("en-US", {
      timeZone: "UTC",
    })
    : "";
}

export function getDateFromDatePicker(date: Date): string {
  if (!date) {
    return "";
  }
  const dt = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${dt}/${month}/${year}`;
}

export const parseTs = (ts: string) => {
  if (ts) {
    if (!isNaN(+ts)) {
      return +ts;
    }
  }
  return "";
}
