import React, { ReactNode } from "react";
import Navbar from "../Navbar/navbar";

interface HomeLayoutProps {
  children: ReactNode;
}

export default function HomeLayout({ children }: HomeLayoutProps) {
  return (
    <div>
      <div className="overflow-x-auto container-fluid mx-auto px-20 h-full">
        <Navbar />
        {children}
      </div>
    </div>
  );
}
