import { useLocation } from "react-router-dom";
import HomeLayout from "../../../components/Layout/homeLayout";
import TaskListComponent from "../../../components/ManualTask/TaskListComponent";
import { TaskStatus } from "../../../types";

export default function TaskList() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    return (
        <HomeLayout>
            <TaskListComponent status={queryParams.get("status") as TaskStatus || "OPEN"} />
        </HomeLayout>
    );
}