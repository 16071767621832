const deliveryData = [
  {
    id: "123",
    organization_id: "2345",
    load_num: "#123",
    load_source: "Alyvs",
    truck_num: "T123",
    trailer_num: "Tr123",
    driver: {
      id: "42",
      organization_id: "2345",
      driver_name: "John Smith",
      external_systems: [
        {
          external_id: "54321",
          external_system: "Alvys",
        },
        {
          external_id: "65432",
          external_system: "Samsara",
        },
      ],
      contact_email: "driver@email.com",
      contact_phone: "5551112222",
      legal_time_status: {
        duty_status: "Active",
        time_in_current_status: {
          hours: 6,
          minutes: 14,
        },
        time_until_break: {
          hours: 7,
          minutes: 15,
        },
        drive_remaining: {
          hours: 8,
          minutes: 16,
        },
        shift_remaining: {
          hours: 9,
          minutes: 17,
        },
        days_since_last_reset: 2.4,
        cycle_remaining: {
          hours: 11,
          minutes: 19,
        },
        cycles_future_days: [
          {
            future_day: 0,
            hours: 1,
            minutes: 2,
          },
          {
            future_day: 1,
            hours: 2,
            minutes: 3,
          },
          {
            future_day: 2,
            hours: 3,
            minutes: 4,
          },
          {
            future_day: 3,
            hours: 4,
            minutes: 5,
          },
          {
            future_day: 4,
            hours: 5,
            minutes: 6,
          },
          {
            future_day: 5,
            hours: 6,
            minutes: 7,
          },
          {
            future_day: 6,
            hours: 7,
            minutes: 8,
          },
          {
            future_day: 7,
            hours: 8,
            minutes: 9,
          },
        ],
      },
    },
    customer_name: "Tony Signh",
    invoice_num: "I123",
    stops: [
      {
        id: "098",
        external_id: "567",
        stop_order: 1,
        customer_name: "Fin Watterson",
        location: {
          address1: "123 Main St.",
          address2: "Apt #1",
          city: "Beverly Hills",
          state: "CA",
          zipcode: "90210",
          lat: 34.081809,
          lng: -118.4855006,
        },
        deliver_date: "2024-02-28T08:00:00-08:00",
        deliver_status: "On Time",
        deliver_notes: "Call Tony on arrival",
      },
      {
        id: "087",
        external_id: "5678",
        stop_order: 2,
        customer_name: "Nitin Kumar",
        location: {
          address1: "1428 Elm St.",
          address2: "Apt #2",
          city: "Springwood",
          state: "OH",
          zipcode: "44410",
          lat: 40.1452893,
          lng: -92.1272278,
        },
        deliver_date: "2024-02-29T15:00:00-08:00",
        deliver_status: "Pending",
        deliver_notes: "Beware, if you fall asleep!!!",
      },
    ],
    current_location: {
      address1: "7770 Milton E Proby Pkwy",
      address2: "Test Data",
      city: "Colorado Springs",
      state: "CO",
      zipcode: "80916",
      lat: 38.801712,
      lng: -104.92317,
    },
    destination_location: {
      lat: 38.801712,
      lng: -104.92317,
    },
    location_updated: "2024-02-25T14:21:19.222000-08:00",
    notes: [
      {
        id: "3333",
        stop_id: "098",
        reason_code: "Traffic",
        note_text: "3 hours left and 120 miles to destination",
      },
      // {
      //   id: "4444",
      //   stop_id: "087",
      //   reason_code: "Truck Broken",
      //   note_text: "no driving hours till Wed, 100 miles to destination",
      // },
      // {
      //   id: "5555",
      //   stop_id: "098",
      //   reason_code: "Weather",
      //   note_text: "past deliver date, 89 miles to destination",
      // },
    ],
    deliver_status: {
      current_stop: "098",
      current_eta: "2024-02-28T10:00:00-08:00",
      current_eta_calculated: "2024-02-24T11:12:13.140000-08:00",
      status: "Estimated Late",
      status_reason: "98 minutes to delivery, 100 miles to destination",
    },
  },
];
export default deliveryData;
