export function translateDutyStatus(status) {
    switch(status) {
      case '':
        return 'Disconnected'
      case 'offDuty':
        return 'Off Duty'
      case 'sleeperBed':
        return 'Sleeper Bed'
      case 'driving':
        return 'Driving'
      case 'onDuty':
        return 'On Duty'
      case 'yardMove':
        return 'Yard Move'
      case 'personalConveyance':
        return 'Personal Conveyance'
      default:
        return status
    }
  }