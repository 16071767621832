import { XMarkIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
// import MailForwardIcon from "../../icons/MailForward";
import Modal from "react-modal";
import { beautifyTime, formatDateTimeString } from "../../utils/dateutils";
import { copyInnerText } from "../../utils/clipboard";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/currency";

const customStyles = {
    content: {
        padding: "-20px",
        paddingBottom: "-20px",
        margin: "50px"
    },
};

interface DetentionMessage {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    trip: any;
}

export default function DetentionMessage({ isOpen, setIsOpen, trip }: DetentionMessage) {
    const getJSXMessage = () => {
        return (
            <div id="detention_message">
                Dear Customer,<br /><br />

                Trip #{trip?.trip_num} is complete and had detention. Our truck had a total detention duration of {beautifyTime(trip?.detention_summary
                    ?.detention_total_duration?.value, "", {
                    minuteUnit: "mins",
                    hourUnit: "hrs"
                })} with detention amount of {formatCurrency(trip?.detention_summary?.detention_total_amount)}.<br />
                Below is the trip information:<br /><br />

                {(trip?.detention || []).filter(d => !d.is_loss && d?.detention_detail?.total_amount).map((detention) => {
                    const stop = trip?.stops?.find(s => s.id === detention.stop_id) || {};
                    return (<>
                        Stop {stop.stop_order}:<br />
                        Customer Order Number: {trip?.load_num}<br />
                        Customer: {stop?.customer_name}<br />
                        Location: {stop?.location?.city}, {stop?.location?.state}<br />
                        Appointment Date: {formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                            time: false,
                            date: true,
                        })}<br />
                        Appointment Time: {formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                            time: true,
                            date: false,
                        })}<br />
                        Arrival time: {formatDateTimeString(detention?.arrival_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                            time: true,
                            date: false,
                        })}<br />
                        Departure time: {formatDateTimeString(detention?.departure_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                            time: true,
                            date: false,
                        })}<br />
                        Detention: {beautifyTime(detention?.detention_detail?.duration?.value, "0 hrs", {
                            minuteUnit: "mins",
                            hourUnit: "hrs"
                        })}<br />
                        Detention Rate: {formatCurrency(detention?.detention_detail?.rate)}/Hour<br />
                        Detention Amount: {formatCurrency(detention?.detention_detail?.total_amount, "$0")}<br /><br />
                    </>);
                })}

                Please approve the detention payment.<br /><br />
                Thank you!<br /><br />
                Dispatch Team<br />
                {/* Tamana Truck Lines */}
            </div>)
    }
    return (<Modal
        style={customStyles}
        isOpen={isOpen}
        overlayClassName="overlay-detention-message opacity-1"
    >
        {/* <div className="p-20 h-full" style={{ "background": "rgba(22, 22, 22, 0.6)" }}> */}
        <div className="bg-[#EDEDF6] flex justify-center items-center m-auto min-h-[830px]">
            <div className="flex flex-col right-0">
                <div className="flex flex-row gap-2 justify-end">
                    {/* <div className="basis-[85%]"></div> */}
                    <div className="pb-2 flex flex-row gap-8">
                        {/* <div>
                            <MailForwardIcon className="w-6 h-6" />
                        </div> */}
                        <div className="inline-flex cursor-pointer" onClick={() => {
                            copyInnerText("detention_message", toast);
                        }}>
                            Copy text
                            <ClipboardDocumentIcon className="ml-1 w-6 h-6" />
                        </div>
                        {/* <div>
                            <ArrowDownTrayIcon className="w-6 h-6" />
                        </div> */}
                    </div>
                </div>
                <div className="bg-[#FFFFFF] py-20 px-20 rounded shadow-lg m-auto">
                    {getJSXMessage()}
                </div>
            </div>
        </div>
        {/* </div> */}
        <span
            className={`fixed top-20 right-20 p-3`}
        >
            <XMarkIcon
                className="w-6 h-6"
                onClick={() => setIsOpen(false)}
            />
        </span>
    </Modal>)
}