export default function BaseButton({ loading, type, onClick, children, className }: { loading?: boolean, type?: "submit" | "reset", onClick: React.MouseEventHandler<HTMLButtonElement>, children: React.ReactNode, className?: string }) {
    return (
        <button
            className={className || undefined}
            onClick={onClick}
            type={type || "button"}
        >
            {loading === true ? <span className="inline-block w-4 h-4 border-2 border-t-2 border-t-black border-white rounded-full animate-spin" /> : children}
        </button>
    )
}
