/**
 * Converts Firebase Auth Error Code to User Friendly Message
 * You can find the list of messages at https://firebase.google.com/docs/auth/admin/errors
 * @param authCode Firebase Auth Error Code
 * @returns User friendly message
 */
export function mapAuthCodeToMessage(authCode) {
  switch (authCode) {
    case "auth/invalid-password":
      return "Password provided is not corrected";

    case "auth/invalid-email":
      return "Email provided is invalid";

    case "auth/invalid-credential":
      return "Invalid Credential";

    default:
      return "";
  }
}
