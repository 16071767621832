import { XMarkIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
// import MailForwardIcon from "../../icons/MailForward";
import Modal from "react-modal";
import { beautifyTime } from "../../utils/dateutils";
import { copyInnerText } from "../../utils/clipboard";
import { toast } from "react-toastify";

const customStyles = {
    content: {
        padding: "-20px",
        paddingBottom: "-20px",
        margin: "50px"
    },
};

// Actions for icons

interface DetentionMessage {
    tripId: string;
    customerName: string;
    orderNumber: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    stopData: any;
    appointmentTime: string;
    appointmentDate: string;
    arrivalTime: string;
    chargeStartTime: {
        utc_datetime: string;
    };
    startTime: {
        utc_datetime: string;
    };
    modifiedTime: string;
    isLoss: boolean;
    elapsed_time_since_start_time: string;
    time_remaining_charge_start_time: string;
}

export default function PreDetentionMessage({ isOpen, startTime,
    setIsOpen, tripId, customerName, arrivalTime, modifiedTime,
    orderNumber, stopData, appointmentTime, appointmentDate, chargeStartTime, isLoss, time_remaining_charge_start_time, elapsed_time_since_start_time }: DetentionMessage) {
    const getJSXMessage = () => {
        return (
            <div id="pre_detention_message">
                {isLoss ?
                    <>
                        Dear Customer,<br />
                        For Trip # {tripId}, our truck arrived at the facility {elapsed_time_since_start_time} ago.<br />
                        Below is the stop information:<br /><br />

                        Customer Order Number: {orderNumber}<br />
                        Customer: {customerName}<br />
                        Location: {stopData?.location?.city}, {stopData?.location?.state}<br />
                        Appointment Date: {appointmentDate}<br />
                        Appointment Time: {appointmentTime}<br />
                        Arrival time: {arrivalTime}<br /><br />

                        Please contact the facility and relieve our truck and driver.<br /><br />

                        Thank you!<br /><br />
                        Dispatch Team<br />
                        {/*Tamana Truck Lines*/}
                    </>
                    :
                    <>
                        Dear Customer,<br />
                        Trip # {tripId} will go into detention in {time_remaining_charge_start_time}. Our truck arrived on time as per the appointment and has been at the facility for {elapsed_time_since_start_time}. <br />
                        Below is the stop information:<br /><br />

                        Customer Order Number: {orderNumber}<br />
                        Customer: {customerName}<br />
                        Location: {stopData?.location?.city}, {stopData?.location?.state}<br />
                        Appointment Date: {appointmentDate}<br />
                        Appointment Time: {appointmentTime}<br />
                        Arrival time: {arrivalTime}<br /><br />

                        Please contact the facility and relieve our truck and driver before detention starts.<br /><br />

                        Thank you!<br /><br />
                        Dispatch Team<br />
                        {/*Tamana Truck Lines*/}
                    </>
                }
            </div>
        );
    }
    return (<Modal
        style={customStyles}
        isOpen={isOpen}
        overlayClassName="overlay-detention-message opacity-1"
    >
        {/* <div className="p-20 h-full" style={{ "background": "rgba(22, 22, 22, 0.6)" }}> */}
        <div className="bg-[#EDEDF6] flex justify-center items-center m-auto min-h-[830px]">
            <div className="flex flex-col right-0">
                <div className="flex flex-row gap-2 justify-end">
                    {/* <div className="basis-[85%]"></div> */}
                    <div className="pb-2 flex flex-row gap-8">
                        {/* <div>
                            <MailForwardIcon className="w-6 h-6" />
                        </div> */}
                        <div className="inline-flex cursor-pointer" onClick={() => {
                            copyInnerText("pre_detention_message", toast);
                        }}>
                            Copy text
                            <ClipboardDocumentIcon className="ml-1 w-6 h-6" />
                        </div>
                        {/* <div>
                            <ArrowDownTrayIcon className="w-6 h-6" />
                        </div> */}
                    </div>
                </div>
                <div className="bg-[#FFFFFF] py-20 px-20 rounded shadow-lg m-auto">
                    {getJSXMessage()}
                </div>
            </div>
        </div>
        {/* </div> */}
        <span
            className={`fixed top-20 right-20 p-3`}
        >
            <XMarkIcon
                className="w-6 h-6"
                onClick={() => setIsOpen(false)}
            />
        </span>
    </Modal>)
}