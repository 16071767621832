import { isExternalDriver } from "../../utils/triputils";
import CurrentLocationCard from "../CurrentLocation/currentlocation";
import CustomerCard from "../CustomerCard/customerCard";
import DriverCard from "../Driver/driver";
import ExceptonDetailsLayout from "../Layout/exceptonDetailsLayout";
import TripDetails from "../TripDetails/tripDetails";
import ExceptionDetailsData from "../../pages/exceptionDetailsCard/exceptionDetailsData";
import TripTimeline from "../TripTimeline/TripTimeline";
import ExpandableMap from "../map/expandableMap";
import ShiftStatusCard from "../ShiftStatusCard/shiftStatusCard";
import AvailableHoursCard from "../AvailableHoursCard/availablehourscard";
import ExceptionNotesSection from "../ExceptionNotesSection/exceptionnotessection";
import { useAppSelector } from "../../redux/hooks";
import ExceptionDetailsHeader from "../ExceptionDetailsHeader/ExceptionDetailsHeader";
import ExceptionDetailTitle from "../ExceptionDetailsHeader/ExceptionDetailTitle";
import { ExceptionType } from "../../types";

export function EtaExceptionDetails({ curr_data, completed }: {
    curr_data: any;
    completed?: boolean;
}) {
    if (!curr_data?.length) {
        return null;
    }
    const etaTab = useAppSelector((state) => state.data.etaTab);

    return (
        <>
            <ExceptionDetailTitle trip={curr_data} module={ExceptionType.ETA} />
            <ExceptonDetailsLayout
                leftCardSec={
                    <TripDetails
                        curr_data={curr_data}
                        active_stop_num={curr_data[0]?.active_stop_num} />
                }
                rightCardSec={
                    <div>
                        <ExceptionDetailsHeader curr_data={curr_data} completed={completed} taskConfig={{
                            createdFromId: curr_data[0]?.trip_num,
                            createdFromType: "eta"
                        }} />

                        {curr_data?.map((delivery, index) => {
                            return !completed ? (
                                <div
                                    className="flex flex-row gap-4 mb-4 w-full"
                                    key={`del_${index}`}
                                >
                                    <div className="w-3/4 flex flex-col gap-4">
                                        <div className="flex w-full">
                                            <DriverCard
                                                driverName={delivery?.driver?.driver_name}
                                                contactPhone={delivery?.driver?.contact_phone}
                                                truckNum={delivery?.truck_num}
                                                trailerNum={delivery?.trailer_num}
                                            />
                                        </div>
                                        <div className="flex w-full">
                                            <CustomerCard
                                                customerName={delivery?.customer_name}
                                                loadNum={delivery?.load_num}
                                                tmsLinkInfo={delivery?.external_tms_summary}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex p-4 w-1/4 rounded bg-gray-50 text-sm justify-center">
                                        <CurrentLocationCard
                                            address={delivery?.current_location?.address1}
                                            isLate={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.is_late : false}
                                            eta={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.eta : null}
                                            lastUpdated={delivery?.current_location?.last_updated}
                                            delivery_timezone={delivery?.delivery_timezone}
                                            isExternalDriver={isExternalDriver(curr_data[0]?.driver)}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="flex flex-row gap-4 mb-4 w-full"
                                    key={`del_${index}`}
                                >
                                    <div className="w-full flex flex-col">
                                        <div className="flex w-full">
                                            <DriverCard
                                                driverName={delivery?.driver?.driver_name}
                                                contactPhone={delivery?.driver?.contact_phone}
                                                truckNum={delivery?.truck_num}
                                                trailerNum={delivery?.trailer_num}
                                                completed={completed}
                                            />
                                        </div>
                                        <div className="flex w-full">
                                            <CustomerCard
                                                customerName={delivery?.customer_name}
                                                loadNum={delivery?.load_num}
                                                tmsLinkInfo={delivery?.external_tms_summary}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                        {(isExternalDriver(curr_data[0]?.driver) && !completed) &&
                            (<div className="w-full">
                                <div className="p-4 rounded mb-4 bg-blue-500 text-white text-bold text-center">
                                    <i className="fa-solid fa-bell me-3"></i>HoS data unavailable
                                    for non-company ELD
                                </div>
                            </div>)
                        }

                        {(!isExternalDriver(curr_data[0]?.driver) && !completed) &&
                            ExceptionDetailsData.map((delivery, index) => (
                                <div key={`ex_del_${index}`} className="w-full">
                                    <TripTimeline
                                        delivery={delivery}
                                        currentLocation={curr_data[0]?.current_location}
                                        destinationLocation={curr_data[0]?.destination_location}
                                        local_time_offset={curr_data[0]?.delivery_offset_time}
                                        delivery_timezone={curr_data[0]?.delivery_timezone}
                                        eta_details={curr_data[0]?.eta_details}
                                        at_current_destination={curr_data[0]?.at_current_destination}
                                    />
                                </div>
                            ))
                        }

                        {(curr_data[0]?.current_location?.lat && !completed) ?
                            <ExpandableMap
                                curr_data={curr_data}
                            /> : null}

                        {(!isExternalDriver(curr_data[0]?.driver) && !completed) &&
                            curr_data?.map((delivery, index) => (
                                <div className="sm:flex gap-4 mb-4" key={`int_del_${index}`}>
                                    <ShiftStatusCard
                                        legalTimeStatus={delivery?.driver?.legal_time_status}
                                    />
                                    <AvailableHoursCard
                                        delivery={delivery}
                                        currentData={curr_data}
                                    />
                                </div>
                            ))}

                        <ExceptionNotesSection originId={curr_data[0]?.trip_num} originType="TRIP" exceptionType="ETA" />
                    </div>
                }
            ></ExceptonDetailsLayout>
        </>
    );
}