import React from "react";
import { useState } from "react";
import ShiftHoursModal from "../../components/ShiftHoursModal/shifthoursmodal";
import { displayTimeDetails } from "../../utils/dateutils";


export default function AvailableHoursCard(props) { 
    const [showModel, setshowModel] = useState(false);
    const [showDayCalc, setShowDayCalc] = useState(0);

    const openModel = (day) => {
        setShowDayCalc(day - 1)
        setshowModel(true)
    }

    return ( 
        <>      
            <div className="p-4 sm:w-1/2 w-full rounded bg-gray-50">
                <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9]">
                    <div className="text-[#4F5B67]">Available Hours For Future Dates</div>
                </div>
                {props.delivery?.driver?.hos?.map(
                    (cycle, cycleIndex) => (
                        <React.Fragment key={cycleIndex}>
                            {cycle?.future_day != 0 ? (
                            <>
                            <div className="flex pb-1 text-sm justify-between border-b-2 border-[#A8B0B9]">
                                <div className="flex">
                                <div className="m-auto">
                                    <div className="flex flex-wrap">
                                    <div className="text-[#4F5B67]">Day +{cycle?.future_day} ({
                                        new Intl.DateTimeFormat("en-us", { weekday: "short" }).format(new Date(new Date().setDate(new Date().getDate() + cycleIndex)))
                                        })</div>
                                    <div className="text-xs pl-2 py-1"><button onClick={() => openModel(cycle?.future_day)}><i className="fa-solid fa-circle-info"></i></button></div>
                                    </div>
                                    <div className="text-[#7F8B97] text-xs">{ 
                                    new Date(new Date().setDate(new Date().getDate() + cycleIndex)).toLocaleDateString()
                                    }
                                    </div>  
                                </div>
                                </div>
                                
                                <div className="text-[#1C244A] justify-items-end">
                                {displayTimeDetails(cycle)}
                                </div>
                            </div>
                                </>
                            ):(<></>)}
                        </React.Fragment>
                    )
                )}
            </div>
            <ShiftHoursModal currentData={props.currentData}
            day = {showDayCalc}
            showModel = {showModel}
            setshowModel = {setshowModel}/>
        </>
    )
}