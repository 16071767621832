import { handleErrorAction, withAuthHeader } from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Create an async thunk action
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/aggregation/loads",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              username: process.env.REACT_APP_ALYVS_USERNAME,
              password: process.env.REACT_APP_ALYVS_PASSWORD,
              token: process.env.REACT_APP_SAMSARA_KEY,
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

// Create an async thunk action
export const fetchCompletedData = createAsyncThunk(
  "data/fetchCompletedData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/aggregation/loads",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              username: process.env.REACT_APP_ALYVS_USERNAME,
              password: process.env.REACT_APP_ALYVS_PASSWORD,
              token: process.env.REACT_APP_SAMSARA_KEY,
              trip_status: "DELIVERED",
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    data: [],
    completedData: [],
    completedDataTS: 0,
    dataTS: 0,
    notes: [],
    notesModified: false,
    loading: false,
    completedLoading: false,
    error: null,
    completedError: null,
    clickIndex: "",
    etaTab: 0,
    availableTrucks: [], // Added this line
  },
  reducers: {
    setClickIndex: (state, action) => {
      state.clickIndex = action.payload;
    },
    setEtaTab: (state, action) => {
      state.etaTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.dataTS = Date.now();
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCompletedData.pending, (state) => {
        state.completedLoading = true;
      })
      .addCase(fetchCompletedData.fulfilled, (state, action) => {
        state.completedLoading = false;
        state.completedData = action.payload;
        state.completedDataTS = Date.now();
      })
      .addCase(fetchCompletedData.rejected, (state, action) => {
        state.completedLoading = false;
        state.completedError = action.payload;
      });
  },
});
