import React, { ReactNode, memo } from "react";

interface ExceptonDetailsLayoutProps {
    leftCardSec: ReactNode;
    rightCardSec: ReactNode; 
}

const ExceptonDetailsLayout: React.FC<ExceptonDetailsLayoutProps> = memo(({ leftCardSec, rightCardSec }) => {
    return (
        <div className="sm:flex w-[100%]"> 
            <aside id="sidebar-multi-level-sidebar" className="w-72 transition-transform 
            translate-x-0" aria-label="Sidebar">
             {leftCardSec}
             </aside>
             <div className="sm:pl-8 w-[100%]">
              <div className="rounded-lg">
            {rightCardSec} 
            </div>
            </div>
        </div>
    );
});

ExceptonDetailsLayout.displayName = 'ExceptonDetailsLayout'
export default ExceptonDetailsLayout;



