import React, { useEffect } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import TripCard from "../../components/ExceptionCards/TripCard";
import useAuthGuard from "../../hooks/useAuthGuard";
import ReeferCard from "../../components/ExceptionCards/ReeferCard";
import DetentionCard from "../../components/ExceptionCards/DetentionCard";
import AvailableTrucksCard from "../../components/ExceptionCards/AvailableTrucksCard";
import { useAppSelector } from "../../redux/hooks";
import CustomExceptionsCard from "../../components/ExceptionCards/CustomExceptionsCard";
import ManualTasks from "../../components/ExceptionCards/ManualTasks";

export default function Home() {
  useAuthGuard();
  const { data, loading } = useAppSelector((state) => state.data);
  const [showTemperatureMonitoring, setShowTemperatureMonitoring] = React.useState(false);
  const [showDetention, setShowDetention] = React.useState(false);

  useEffect(() => {
    if (!loading && data.length > 0) {
      let filteredData = data.filter((trip) => Boolean(trip?.temperature_monitoring_details));
      if (filteredData.length > 0) {
        setShowTemperatureMonitoring(true);
      }
      filteredData = data.filter((trip) => Boolean(trip?.detention_summary));
      if (filteredData.length > 0) {
        setShowDetention(true);
      }
    }
  }, [loading, data]);

  let exceptionCards = [
    {
      title: "Trip Monitoring",
      show: true,
      component: <TripCard />,
    }, {
      title: "Temperature Monitoring",
      show: showTemperatureMonitoring,
      component: <ReeferCard />,
    }, {
      title: "Detention Monitoring",
      show: showDetention,
      component: <DetentionCard />,
    }, {
      title: "Available Trucks",
      show: true,
      component: <AvailableTrucksCard />,
    }, {
      title: "Custom Exceptions",
      show: false,
      component: <CustomExceptionsCard />,
    }, {
      title: "Tasks",
      show: false,
      component: <ManualTasks />,
    }];

  const getNextExceptionCard = () => {
    do {
      if (exceptionCards.length === 0) {
        return null;
      }
      const card = exceptionCards.shift();
      if (card.show) {
        return card.component;
      }
    } while (true);
  };

  return (
    <HomeLayout>
      <p className="text-2xl font-semibold text-gray-700 m-2">
        Monitoring
      </p>
      <hr className="mb-4 border-gray-300" />
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {
          [...exceptionCards].map((_, index) => (
            <div className="mx-auto flex justify-center mb-10" key={`card_${index}`}>
              {getNextExceptionCard()}
            </div>
          ))
        }
      </div>
    </HomeLayout>
  );
}
