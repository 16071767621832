import OutlinedButton from "../BlockButton/ClearFilter";
import FilledButton from "../BlockButton/UpdateFilter";
import DatePicker from "../Dropdown/datePicker";
import Dropdown from "../Dropdown/dropdown";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { fetchTaskTypes, fetchTaskDepartments, createTask, updateTask, fetchTasks } from "../../redux/slice/manualTask";
import { useFormik } from 'formik';
import { autocomplete as driversAutoComplete } from "../../redux/slice/drivers";
import { autocomplete as loadsAutoComplete } from "../../redux/slice/loads";
import { autocomplete as trailersAutoComplete } from "../../redux/slice/trailers";
import { autocomplete as trucksAutoComplete } from "../../redux/slice/trucks";
import { autocomplete as customerNamesAutoComplete } from "../../redux/slice/customerName";
import { autocomplete as usersAutoComplete } from "../../redux/slice/users";
import { autocomplete as orderNumberAutoComplete } from "../../redux/slice/orderNumber";
import { getDateFromDatePicker } from "../../utils/dateutils";
import { useNavigate } from "react-router-dom";
import { CreatedFromConfig } from "../../types";

const autocomplete = {
    drivers: driversAutoComplete,
    loads: loadsAutoComplete,
    trailers: trailersAutoComplete,
    trucks: trucksAutoComplete,
    customerNames: customerNamesAutoComplete,
    users: usersAutoComplete,
    orderNumber: orderNumberAutoComplete
};

export default function CreateTaskComponent({ task, taskConfig }: { task?: any; taskConfig?: CreatedFromConfig }) {
    const { taskTypes, departments, loading } = useAppSelector((state) => state.manualTasks);
    const { trucks } = useAppSelector((state) => state.trucks);
    const { loads } = useAppSelector((state) => state.loads);
    const { trailers } = useAppSelector((state) => state.trailers);
    const { drivers } = useAppSelector((state) => state.drivers);
    const { customerNames } = useAppSelector((state) => state.customerName);
    const { users } = useAppSelector((state) => state.users);
    const { orderNumber } = useAppSelector((state) => state.orderNumber);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const optionTextClassName = "font-light";

    const fetchAutoComplete = (type: keyof typeof autocomplete, search: string) => {
        dispatch(autocomplete[type]({
            search: search,
            limit: 10
        }));
    }

    useEffect(() => {
        Object.keys(autocomplete).forEach(element => {
            fetchAutoComplete(element as keyof typeof autocomplete, "");
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            status: task ? task.status : "OPEN",
            taskId: task?.task_id,
            orderNumber: task ? task.order_id : "",
            dueDate: task ? task.due_date : new Date(),
            taskTypeId: task ? task.task_type_id : "",
            departmentId: task ? task.department_id : "",
            tripId: task ? task.trip_id : "",
            truckId: task ? task.truck_id : "",
            trailerId: task ? task.trailer_id : "",
            driverId: task ? task.driver_id : "",
            instruction: task ? task.issue?.description : "",
            description: task ? task.issue?.instruction : "",
            customerName: task ? task.customer_name : "",
            assignedTo: task ? task.assigned_to.user_id : "",
            createdFromId: task ? task?.origin?.meta_data?.created_from_id : taskConfig?.createdFromId,
            createdFromType: task ? task?.origin?.meta_data?.created_from_type : taskConfig?.createdFromType,
        },
        onSubmit: values => {
            const user = users.find(u => u.email === values.assignedTo);
            dispatch((task ? updateTask : createTask)({
                ...values,
                assignedTo: {
                    email: user.email || values.assignedTo,
                    name: user?.email?.split("@")[0] || values.assignedTo
                },
                origin: {
                    ...(task?.origin || {}),
                    meta_data: {
                    created_from_id: values.createdFromId,
                    created_from_type: values.createdFromType
                }}
            })).then((res) => {
                if (res?.payload?._id) {
                    dispatch(fetchTasks()).then(() => {
                        navigate(`/task/view?id=${res?.payload?._id}`);
                    });
                } else {
                    navigate('/task/list');
                }
            }).catch((err) => {
                console.error(err);
            });
        },
    });

    useEffect(() => {
        dispatch(fetchTaskTypes())
        dispatch(fetchTaskDepartments())
    }, []);

    const getSearchText = (arr: any[], comparator: (val: any) => boolean, selector?: string, fallback?: string) => {
        const ele = arr.find(comparator);
        if (ele) {
            return ele[selector || "label"];
        } else {
            return fallback || "";
        }
    }

    const getCreatedFromText = () => {
        let text = "";
        switch(formik.values.createdFromType) {
            case "eta":
                text = "ETA";
                break;
            case "tempViolation":
                text = "Temp Violation";
                break;
            case "detention":
                text = "Detention";
                break;
            }
            text += ` - ${formik.values.createdFromId}`;
            return text;
        }

    return (
        <form>
            <p className="text-2xl font-semibold text-gray-700 m-2">
                {task ? "Edit Task" : "Create New Task"}
            </p>
            <hr className="my-2 border-gray-400" />
            <div className="flex flex-col gap-4">
                <div className="flex flex-row justify-start gap-20">
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Task Type</label>
                        <Dropdown optionTextClassName={optionTextClassName} label="Task Type" width={17} options={taskTypes.map(t => {
                            return {
                                label: t.name,
                                value: t.task_type_id
                            }
                        })}
                            value={formik.values.taskTypeId}
                            onChange={(e) => formik.setFieldValue('taskTypeId', e)}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Department</label>
                        <Dropdown optionTextClassName={optionTextClassName} label="Department" width={17} options={(departments || []).map(t => {
                            return {
                                label: t.name,
                                value: t.department_id
                            }
                        })}
                            value={formik.values.departmentId}
                            onChange={(e) => formik.setFieldValue('departmentId', e)}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Status</label>
                        <Dropdown optionTextClassName={optionTextClassName} label="Department" width={17} options={[
                            {
                                label: "OPEN",
                                value: "OPEN"
                            },
                            {
                                label: "RESOLVED",
                                value: "RESOLVED"
                            },
                        ]}
                            value={formik.values.status}
                            onChange={(e) => formik.setFieldValue('status', e)}
                        />
                    </div>
                </div>
            </div>
            <hr className="my-8 border-gray-400 border-px" />
            <p className="text-lg font-semibold text-[#1C244A] mb-6 uppercase">
                Related To
            </p>
            <div className="flex flex-col gap-8">
                <div className="flex flex-row justify-start gap-20">
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Trip Number</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={loads.map(t => {
                            return {
                                label: t.trip_number,
                                value: t.trip_number
                            }
                        })}
                            initialSearchText={getSearchText(loads, (t) => t.trip_number === formik.values.tripId, "trip_number", formik.values.tripId)}
                            value={formik.values.tripId}
                            onSearchTextChange={(e) => fetchAutoComplete('loads', e)}
                            onChange={(e) => formik.setFieldValue('tripId', e)} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Order Number</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={orderNumber.map(t => {
                            return {
                                label: t.order_number,
                                value: t.order_number
                            }
                        })}
                            initialSearchText={getSearchText(loads, (t) => t.order_number === formik.values.orderNumber, "trip_number", formik.values.orderNumber)}
                            value={formik.values.orderNumber}
                            onSearchTextChange={(e) => fetchAutoComplete('orderNumber', e)}
                            onChange={(e) => formik.setFieldValue('orderNumber', e)} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Customer Name</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={customerNames.map(t => {
                            return {
                                label: t,
                                value: t
                            }
                        })}
                            initialSearchText={formik.values.customerName}
                            value={formik.values.customerName}
                            onSearchTextChange={(e) => fetchAutoComplete('customerNames', e)}
                            onChange={(e) => formik.setFieldValue('customerName', e)} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Truck Number</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={trucks.map(t => {
                            return {
                                label: t.truck_id,
                                value: t.truck_id
                            }
                        })}
                            initialSearchText={getSearchText(trucks, (t) => t.truck_id == formik.values.truckId, "truck_id", formik.values.truckId)}
                            value={formik.values.truckId}
                            onSearchTextChange={(e) => fetchAutoComplete('trucks', e)}
                            onChange={(e) => formik.setFieldValue('truckId', e)} />
                    </div>
                </div>
                <div className="flex flex-row justify-start gap-20">
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Trailer Number</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={trailers.map(t => {
                            return {
                                label: t,
                                value: t
                            }
                        })}
                            initialSearchText={formik.values.trailerId}
                            value={formik.values.trailerId}
                            onSearchTextChange={(e) => fetchAutoComplete('trailers', e)}
                            onChange={(e) => formik.setFieldValue('trailerId', e)} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Driver Name</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={drivers.map(t => {
                            return {
                                label: t.name,
                                value: t.driver_id
                            }
                        })}
                            initialSearchText={getSearchText(drivers, (t) => t.driver_id == formik.values.driverId, "name", task?.driver_details?.name)}
                            value={formik.values.driverId}
                            onSearchTextChange={(e) => fetchAutoComplete('drivers', e)}
                            onChange={(e) => formik.setFieldValue('driverId', e)} />
                    </div>
                    {(formik.values.createdFromType && formik.values.createdFromId) && <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Created From</label>
                        <input type="input" value={getCreatedFromText()}
                            disabled
                            className={`rounded-full outline-none py-0.5 px-4 min-w-[17rem] bg-white`} placeholder="Order Number"
                        />
                    </div>}
                </div>
            </div>
            <hr className="my-8 border-gray-400 border-px" />
            <p className="text-lg font-semibold text-[#1C244A] mb-6 uppercase">
                Issue
            </p>
            <div className="flex flex-col gap-8">
                <div className="flex flex-row justify-start gap-20">
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Due Date</label>
                        <DatePicker
                            onChange={(date) => {
                                formik.setFieldValue('dueDate', date);
                            }}
                            placeholder="Enter as MM/DD/YYYYY"
                            initialSearchText={getDateFromDatePicker(formik.values.dueDate ? new Date(formik.values.dueDate) : new Date())}
                            value={formik.values.dueDate ? new Date(formik.values.dueDate).toString() : null}
                            singleDate={true}
                            className={{
                                parent: "min-w-[15.2rem]"
                            }}
                            allowinput={true}
                            inputFormat="MM/DD/YYYY"
                        />
                        {/* <Dropdown varient="rounded-full" search={true} label="Enter as MM/DD/YYYYY" width={17} options={taskTypes.map(t => {
                            return {
                                label: t.name,
                                value: t.task_type_id
                            }
                        })} /> */}
                    </div>
                    {/* <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Created By</label>
                        <input type="input" className={`rounded-full outline-none py-0.5 px-4 min-w-[17rem]`} placeholder="User`s Name" />
                    </div> */}
                    <div className="flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Assigned To</label>
                        <Dropdown optionTextClassName={optionTextClassName} varient="rounded-full" search={true} label="Search or select" width={17} options={users.map(t => {
                            return {
                                label: t.email,
                                value: t.email
                            }
                        })}
                            initialSearchText={getSearchText(users, (t) => t.email == formik.values.assignedTo, "email", formik.values.assignedTo)}
                            value={formik.values.assignedTo}
                            onSearchTextChange={(e) => fetchAutoComplete('users', e)}
                            onChange={(e) => formik.setFieldValue('assignedTo', e)} />
                    </div>
                </div>
                <div className="flex flex-row justify-start gap-20">
                    <div className="basis-[50%] flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Issue Description:</label>
                        <textarea className="rounded-md h-full w-full p-2" rows={7}
                            value={formik.values.instruction}
                            onChange={(e) => formik.setFieldValue('instruction', e.target.value)} />
                    </div>
                    <div className="basis-[50%] flex flex-col gap-2">
                        <label className="text-[#1C244A] font-bold">Instructions:</label>
                        <textarea className="rounded-md h-full w-full p-2" rows={7}
                            value={formik.values.description}
                            onChange={(e) => formik.setFieldValue('description', e.target.value)} />
                    </div>
                </div>
                <div className="flex flex-row justify-center gap-20">
                    <div className="basis-[50%] flex justify-end gap-2 w-full">
                        <FilledButton loading={task ? loading.updateTask : loading.createTask} type="submit" text={task ? "Update" : "Save"} className="px-16 rounded-md" onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                        }} />
                    </div>
                    <div className="basis-[50%] flex gap-2">
                        <OutlinedButton type="reset" text="Cancel" className="px-14 rounded-md text-[#B40808] border-[#B40808]" onClick={() => {
                            formik.resetForm({ values: formik.initialValues });
                            navigate('/task/list');
                        }} />
                    </div>
                </div>
            </div>
        </form>
    );
}