export enum ExceptionType {
  ETA = "eta",
  TEMP_VIOLATION = "tempViolation",
  DETENTION = "detention",
}
export type TripType = "internal" | "external";

export enum TripStatus {
  Dispatched = "dispatched",
  InTransit = "inTransit",
  Completed = "completed",
  All = "all",
}

export enum ETAStatus {
  OnTime = "onTime",
  Late = "late",
  OnTimePreviouslyLate = "onTimePreviouslyLate",
  All = "all",
}

// export enum ExceptionStatus {
//   Open = "open",
//   InProgress = "inProgress",
//   Closed = "closed",
//   All = "all",
// }

export enum TemperatureComplianceStatus {
  Compliant = "compliant",
  InViolation = "inViolation",
  CompliantPreviouslyInViolation = "compliantPreviouslyInViolation",
  All = "all",
}

export enum DetentionStatus {
  PreDetention = "preDetention",
  InDetention = "inDetention",
  CompletedTripWithDetention = "completedTripWithDetention",
  CompletedTripWithLoss = "completedTripWithLoss",
  All = "all",
}

export interface CreateTaskSchema {
  departmentId: string;
  taskTypeId: string;
  tripId: string;
  truckId: string;
  trailerId: string;
  driverId: string;
  orderNumber: string;
  instruction: string;
  description: string;
  status?: string;
  customerName: string;
  dueDate: string;
  assignedTo: {
    email: string;
    name: string;
  };
  origin: any;
}

export type TaskStatus = "OPEN" | "RESOLVED" | "PAST_DUE" | "DUE_TODAY";

export type CreatedFromConfig = {
  createdFromType: "eta" | "detention" | "tempViolation";
  createdFromId: string;
};
