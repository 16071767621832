import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { withAuthHeader } from "../../utils/api";
import { handleError } from "../../utils/errorhandling";

export const notesData = createAsyncThunk(
  "notes/notes",
  async (
    {
      originId,
      originType,
    }: {
      originId: string;
      originType: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER +
          `/v1/notes/?origin_id=${originId}&origin_type=${originType}`,
        {
          mode: "cors",
          method: "GET",
          headers: await withAuthHeader({
            Accept: "application/json",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.Data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNotes = async (originId, originType, toast): Promise<any[]> => {
  const response = await fetch(
    process.env.REACT_APP_API_SERVER +
      `/v1/notes/?origin_id=${originId}&origin_type=${originType}`,
    {
      headers: await withAuthHeader({ "Content-Type": "application/json" }),
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data.Data.reverse();
  } else {
    handleError(await response.json(), toast, "Failed to fetch notes");
  }
};

export const addNote = async (noteData) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/notes/`, {
      method: "POST",
      headers: await withAuthHeader({ "Content-Type": "application/json" }),
      body: JSON.stringify(noteData),
    });
    const json = await res.json();
    if (res.status !== 200) {
      return {
        success: false,
        message:
          json?.detail || json?.error || json?.message || "Error creating note",
      };
    }
    return {
      success: true,
      data: json,
    };
  } catch (ex) {
    console.error(ex);
    return {
      success: false,
      message: ex.message,
    };
  }
};

export const updateNote = async (note_id, notesData) => {
  const requestOptions = {
    method: "PUT",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify(notesData),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/v1/notes/" + note_id,
    requestOptions
  );
};

export const deleteNote = async (note_id) => {
  if (!note_id) {
    return;
  }
  const requestOptions = {
    method: "DELETE",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/v1/notes/" + note_id,
    requestOptions
  );
};

export const updateFlag = async (trip_id, flag, completed?: boolean) => {
  const requestOptions = {
    method: "POST",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify({ trip_id: trip_id, flag: flag }),
  };
  const response = await fetch(
    process.env.REACT_APP_API_SERVER + `/v1/exception-status-tracker/flag${completed ? "?is_completed_trip=true" : ""}`,
    requestOptions
  );
  if (response.ok) {
    return true;
  } else {
    return false;
  }
};

// Create a slice for the data with reducers to handle the actions
export const notesSlice = createSlice({
  name: "notesData",
  initialState: {
    notes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(notesData.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(notesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
