"use client";
import React, { useState } from "react";
import { useEffect } from "react"; 
import { fetchData, fetchCompletedData } from "../../redux/slice/data";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useAuthGuard from "../../hooks/useAuthGuard";
import "./exceptionDetailsCard.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import { EtaExceptionDetails } from "../../components/ExceptionDetails/EtaExceptionDetails";
import { TempViolationExceptionDetails } from "../../components/ExceptionDetails/TempViolationExceptionDetails";
import { DetentionExceptionDetails } from "../../components/ExceptionDetails/DetentionExceptionDetails";
import { ExceptionType } from "../../types";
import { handleError } from "../../utils/errorhandling";
import { setClickIndex, setEtaTab } from "../../redux/reducer";

export default function ExceptionDetailsCard() {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: liveData, completedData, loading, completedLoading } = useAppSelector((state) => state.data);
  let { clickIndex } = useAppSelector((state) => state.data);
  const location = useLocation();
  const [curr_data, setCurrData] = React.useState([]);
  const queryParams = new URLSearchParams(location.search);
  const module = queryParams.get("module") || ExceptionType.ETA;
  const [completed, setCompleted] = useState<boolean>(queryParams.get("completed") === "true");
  const id = queryParams.get("id");
  let data = (completed ? completedData : liveData);

  useEffect(() => {
    let dt;
    data = (completed ? completedData : liveData);
    if (id) {
      dispatch(setClickIndex(id));
      dt = data?.filter((val) => val.trip_num == id);
      if (!dt.length) {
        dt = completedData?.filter((val) => val.trip_num == id);
        if (dt.length) {
          setCompleted(true);
        }
      }
    }
    setCurrData(dt);
  }, [completedData, liveData, id]);

  useEffect(() => {
    if (!liveData?.length && !loading) {
      dispatch(fetchData()).then((actionResult) => {
        if (actionResult.type === "data/fetchData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
    if (!completedData?.length && !completedLoading) {
      dispatch(fetchCompletedData()).then((actionResult) => {
        if (actionResult.type === "data/fetchCompletedData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setEtaTab(0));
  }, []);

  if (loading || completedLoading) {
    return <Loading />;
  }

  if (!curr_data?.length) {
    return (<div className="flex w-full h-fit align-middle m-auto justify-center p-2 mt-5 bg-red-300 text-gray-100 rounded-md">
      No Data Found for {id}
    </div>);
  }

  switch (module) {
    case ExceptionType.ETA:
      return <EtaExceptionDetails curr_data={curr_data} completed={completed} />
    case ExceptionType.TEMP_VIOLATION:
      return <TempViolationExceptionDetails curr_data={curr_data} completed={completed} />
    case ExceptionType.DETENTION:
      return <DetentionExceptionDetails curr_data={curr_data} completed={completed} />
    default:
      return <EtaExceptionDetails curr_data={curr_data} completed={completed} />
  }
}
