import React from 'react';

export function ConfirmationPopup({ isOpen, onClose, onConfirm, title, message }) {
  return (
    <div className={`fixed inset-0 flex items-center justify-center z-20 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-indigo-500/100 text-white rounded hover:bg-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}