import React from "react";
import { XMarkIcon, ArrowsPointingOutIcon } from "@heroicons/react/24/solid";
import CreateMap from "./map";
import Modal from "react-modal";

const customStyles = {
  content: {
    margin: "40px",
  },
};

interface BaseMapProps {
  curr_data: any[];
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  mapLayer?: string;
  handleMapLayerChange?: (mapLayer: string) => void;
}

const BaseMap: React.FC<BaseMapProps> = ({
  curr_data,
  expanded,
  setExpanded,
  mapLayer,
  handleMapLayerChange,
}) => {
  return (
    <>
      <div
        className={`relative w-full ${expanded ? "h-full" : ""} mb-4 border-2`}
      >
        <div className={`w-full ${expanded ? "h-full" : ""}`}>
          {curr_data[0]?.current_location.lat && curr_data[0]?.destination_location?.lat ? (
            <CreateMap
              currentLocation={{
                lat: curr_data[0]?.current_location?.lat,
                lng: curr_data[0]?.current_location?.long,
              }}
              destinationLocation={{
                lat: curr_data[0]?.destination_location?.lat,
                lng: curr_data[0]?.destination_location?.long,
              }}
              stops={curr_data[0]?.stops}
              activeStopNumber={curr_data[0]?.active_stop_num}
              expanded={expanded}
              mapLayer={mapLayer}
              handleMapLayerChange={handleMapLayerChange}
            />
          ) : null}
        </div>
        <span
          className={`absolute top-0 right-0 p-1 bg-black opacity-50`}
        >
          {expanded ? (
            <XMarkIcon
              className="w-6 h-6 text-white"
              onClick={() => setExpanded(false)}
            />
          ) : (
            <ArrowsPointingOutIcon
              className="w-5 h-5 text-white"
              onClick={() => setExpanded(true)}
            />
          )}
        </span>
      </div>
    </>
  );
};

const ExpandableMap: React.FC<{
  curr_data: any[];
}> = ({
  curr_data,
}) => {
    const [mapLayer, setMapLayer] = React.useState("normal");
    const [expanded, setExpanded] = React.useState(false);
    const handleMapLayerChange = () => {
      setMapLayer((previous) => previous === "normal" ? "satellite" : "normal");
    }
    if (!expanded) {
      return <BaseMap expanded={expanded} setExpanded={setExpanded} curr_data={curr_data} mapLayer={mapLayer} handleMapLayerChange={handleMapLayerChange} />
    } else {
      return <Modal
        style={customStyles}
        isOpen={expanded}
        overlayClassName="overlay overlay-map"
      >
        <BaseMap expanded={expanded} setExpanded={setExpanded} curr_data={curr_data} mapLayer={mapLayer} handleMapLayerChange={handleMapLayerChange} />
      </Modal>
    }
  }

export default ExpandableMap;
