import React from 'react';
import { useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  TimeScale
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { displayTime, displayShortDate, displayDate } from "../../utils/dateutils";

type TempHistoryGraphProps = {
    tempHistoryList?: [];
    requiredTemp: number;
    minTemp: number;
    maxTemp: number;
    timezoneOffset: number;
  };

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  TimeScale
);



export default function TempHistoryGraph(props: TempHistoryGraphProps) {
    const [tempList, setTempList] = useState([]);
    const [keyList, setKeyList] = useState([]);
    const [maxTempList, setMaxTempList] = useState([])
    const [minTempList, setMinTempList] = useState([])

    if(props.tempHistoryList && tempList.length == 0) {
        var temps = []
        var keys = []
        var min_temps = []
        var max_temps = []
        const stepSize = Math.ceil(props.tempHistoryList.length / 150)
        
        for (let i = 0; i < props.tempHistoryList.length; i += stepSize) {
            temps.push(props.tempHistoryList[i]['temp']);
            min_temps.push(props.minTemp)
            max_temps.push(props.maxTemp)
            var time = props.tempHistoryList[i]['time_ms'] + props.timezoneOffset * 60 * 1000
            var date = displayDate + " " + displayTime(new Date(time))
            keys.push(props.tempHistoryList[i]['time_ms'] + props.timezoneOffset * 60 * 1000)
        }
        setTempList(temps)
        setKeyList(keys)
        setMaxTempList(max_temps)
        setMinTempList(min_temps)
    }

    var options = {
        interaction: {
            intersect: false,
            mode: 'index',
          },
        plugins: {
            tooltip: {
                callbacks: {
                    title: function(items) {
                        const date = new Date(Number(items[0].label));
                        var displayDate = displayShortDate(date)
                        var date_str = displayDate + " " + displayTime(date)
                        return date_str
                    }
                }
              },
            legend: {
                display: false,
            } 
        },
        responsive: true,
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea:false
                }
            }],
          x: {
            grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                display: true,  
                callback: function(index, value, values) {
                    if(index % 2 == 0) {
                        const date = new Date(keyList[index]);
                        var displayDate = displayShortDate(date)
                        var date_str = displayDate + " " + displayTime(date)
                        const prevDate = index > 0 ? new Date(keyList[index - 2]) : null;
                        if ((index == 0) || (date.getUTCDay() != prevDate.getUTCDay())) {
                            return displayDate
                        } else {
                            return ""
                        }
                    } else {
                        return ""
                    }
                }
            },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Temp (F)'
            },
            suggestedMin: -20,
            suggestedMax: 40,
          }
        }
      }
  }
    
    var data = {
        labels: keyList,
        datasets: [
          {
            label: 'Max Temp',
            fill: false,
            backgroundColor: '#008000',
            borderColor: '#008000',
            pointStyle: false,
            data: maxTempList,
          }, {
            label: 'Truck Temp',
            fill: false,
            type: 'line',
            pointRadius: 1.7,
            lineTension: 0.4,
            pointBackgroundColor: function(context) {
                const value = context.dataset.data[context.dataIndex];
                return (value < props.minTemp || value > props.maxTemp) ? '#800000' : '#000080';
            },
            pointBorderColor: function(context) {
                const value = context.dataset.data[context.dataIndex];
                return (value < props.minTemp || value > props.maxTemp) ? '#800000' : '#000080';
            },
            backgroundColor: function(context) {
                var value = context.dataset.data[context.dataIndex];
                return ((value > props.minTemp) && (value < props.maxTemp)) ? '#888888' : '#888888';
            },
            segment: {
                borderColor: function(context) {
                    var p1 = context.p0.parsed.y
                    var p2 = context.p1.parsed.y
                    var p1_exception = !((p1 > props.minTemp) && (p1 < props.maxTemp))
                    var p2_exception = !((p2 > props.minTemp) && (p2 < props.maxTemp))
                    if (p1_exception || p2_exception) {
                       return '#800000'
                    } else {
                       return '#000080'
                    }
                },
              },
            data: tempList
        }, {
            label: 'Min Temp',
            backgroundColor: '#008000',
            borderColor: '#008000',
            pointStyle: false,
            data: minTempList,
            fill: false,
          }
        ]
      };

    return (
        <div>
            <Chart type='line' data={data} options={options} redraw={true} />
        </div>
    );
}