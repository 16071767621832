import { fetchTasks } from "../../redux/slice/manualTask";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PencilIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDateFromDatePicker } from "../../utils/dateutils";
import { TaskStatus } from "../../types";
import { getFilteredTasks } from "../../utils/taskutils";
import ExceptionStatusDropdown from "../Dropdown/exceptionStatusDropdown";
import { autocomplete } from "../../redux/slice/users";

export default function TaskListComponent({ status }: { status: TaskStatus }) {
    const dispatch = useAppDispatch();
    const { tasks } = useAppSelector((state) => state.manualTasks);
    const { users } = useAppSelector((state) => state.users);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    useEffect(() => {
        dispatch(fetchTasks());
        dispatch(autocomplete({
            search: "",
            limit: 100
        }));
    }, []);
    const navigate = useNavigate();

    const renderTable = () => {
        return (
            <div className="flex flex-col overflow-x-auto">
                <table
                    className="whitespace-nowrap overflow-x-auto min-w-full text-sm text-left rtl:text-right text-white"
                >
                    <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
                        <tr className="shadow-md">
                            <th scope="col" className="rounded-bl-lg rounded-tl-lg px-6 py-3">
                                Task Created
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Due Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Task Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Department
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Created By
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Assigned to
                            </th>
                            <th scope="col" className="rounded-br-lg rounded-tr-lg px-6 py-3">
                                Edit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            className="rounded-lg bg-tableData-bg border-b text-gray-900 font-semibold"
                        >
                            <td colSpan={8} className="px-6 py-3"></td>
                        </tr>
                        {getFilteredTasks(tasks, status, selectedUsers)?.map((item, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    onClick={() => {
                                        navigate(`/task/view?id=${item._id}`);
                                    }}
                                    key={item._id}
                                    className="shadow-md bg-white text-gray-900 font-semibold hover:cursor-pointer"
                                >
                                    <td className="px-6 py-3">{item?.created_at ? getDateFromDatePicker(new Date(item?.created_at)) : ""}</td>
                                    <td className="px-6 py-3">{item?.due_date ? getDateFromDatePicker(new Date(item?.due_date)) : ""}</td>
                                    <td className="px-6 py-3">{item?.task_type?.name}</td>
                                    <td className="px-6 py-3">{item?.department_details?.name}</td>
                                    <td className="px-6 py-3">{item?.created_by?.name}</td>
                                    <td className="px-6 py-3">{item?.assigned_to?.name}</td>
                                    <td className="px-6 py-3">
                                        <PencilIcon className="h-5 w-5 text-[#1C244A]" onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/task/edit?id=${item._id}`);
                                        }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className="px-6 py-1"></td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    
    return (
        <div className="sm:px-11 sm:mt-4">
            <div
                className="text-xl mb-6 text-titleColor font-semibold"
            >
                Open Tasks
            </div>
            <div className="flex flex-row justify-between align-middle my-6">
                <ExceptionStatusDropdown
                    options={users.map((user) => {
                        return {
                            label: user?.email?.split("@")[0],
                            value: user?.email,
                        };
                    })}
                    multiselect={true}
                    onChange={(selected) => {
                        console.log(selected);
                        setSelectedUsers(selected);
                    }}
                    selectedOptions={selectedUsers}
                    name={"Assigned To"}
                />
            </div>
            <div>
                {renderTable()}
            </div>
        </div>
    );
}