import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

interface ExceptionStatusDropdownProps {
  options: {
    label: string;
    value: string;
  }[];
  label?: string;
  name?: string;
  multiselect: boolean;
  selectedOptions?: string[];
  onChange?: (selectedOptions: string[]) => void;
}

const ExceptionStatusDropdown: React.FC<ExceptionStatusDropdownProps> = ({
  options,
  label,
  multiselect,
  name,
  selectedOptions,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(selectedOptions || []);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (status: string) => {
    let newSelections = [...selectedStatus];
    if (multiselect) {
      if (selectedStatus.includes(status)) {
        newSelections  = selectedStatus.filter((s) => s !== status);
      } else {
        newSelections = [...selectedStatus, status];
      }
    } else {
      newSelections = [status];
      setIsOpen(false);
    }
    setSelectedStatus(newSelections);
    onChange && onChange(newSelections);
  };

  const isSelected = (status: string) => selectedStatus.includes(status);

  return (
    <div className="relative inline-block text-left text-sm" ref={dropdownRef}>
      {label && <label className="block text-black font-bold mb-2">{label}</label>}
      <div
        onClick={handleToggle}
        className={`cursor-pointer inline-flex items-center justify-between px-4 py-1 ${isOpen ? "rounded-t-3xl" : "rounded-full"
          } border bg-white font-medium text-gray-700 focus:outline-none w-full min-w-52 relative`}
      >
        <span className="flex-grow text-center">
          {selectedStatus.length ? `(${selectedStatus.length}) ${name}` : name}
        </span>
        <span className="ml-auto flex-shrink-0">
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </span>
      </div>

      {isOpen && (
        <div
          className="absolute top-100 left-0 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {options.map((option) => (
            <label
              key={option.value}
              className={`flex items-center px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 focus:outline-none ${isSelected(option.value) ? "bg-gray-100" : ""
                }`}
              role="menuitem"
            >
              <input
                type="checkbox"
                checked={isSelected(option.value)}
                onChange={() => handleSelect(option.value)}
                className={"hidden"}
              />
              {multiselect ? <div className="w-4 h-4 p-0.5 border border-gray-300 rounded mr-2 bg-white">
                <div
                  className={`w-full h-full rounded-sm ${isSelected(option.value) ? "bg-activeTabHeadingsBorder" : "hidden"
                    }`}
                />
              </div> : <div className="w-4 h-4 p-0.5 border border-gray-300 rounded-full mr-2 bg-white">
                <div
                  className={`w-full h-full rounded-full ${isSelected(option.value) ? "bg-activeTabHeadingsBorder" : "hidden"
                    }`}
                />
              </div>}
              <span className="text-wrap">
                {option.label}
              </span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExceptionStatusDropdown;
