import { beautifyTime, formatDateTimeString } from "../../utils/dateutils";
import { formatCurrency } from "../../utils/currency";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import PreDetentionMessage from "./PreDetentionMessage";
import React from "react";
import InDetentionMessage from "./InDetentionMessage";

interface DateTime {
    utc_datetime: string;
    offset_minutes: number;
    timezone: string;
}

interface Duration {
    value: number;
    unit: string;
}

interface DetentionDetail {
    rate: number;
    free_waiting_hours: number;
    duration: Duration;
    total_amount: number;
    status: string;
    start_time: DateTime;
    charge_start_time: DateTime;
    elapsed_time_since_start_time: Duration;
    time_remaining_charge_start_time: Duration;
}

interface LossDetail {
    status: string;
    rate: number;
    free_waiting_hours: number;
    loss_seconds: number;
    total_amount: number;
    start_time: DateTime | null;
    charge_start_time: DateTime | null;
    late_duration_total: Duration;
    duration: Duration;
    elapsed_time_since_start_time: Duration;
    time_remaining_charge_start_time: Duration;
}

interface Detention {
    trip_number: string;
    client_id: string;
    stop_id: string;
    stop_type: string;
    appointment_datetime: DateTime;
    arrival_datetime: DateTime | null;
    departure_datetime: DateTime | null;
    is_on_time: boolean;
    is_loss: boolean;
    detention_detail?: DetentionDetail;
    loss_detail?: LossDetail;
    modified_at: string;
}

type DetentionCardProps = {
    detention: Detention;
    trip: any;
    completed?: boolean;
}
export default function DetentionCard(props: DetentionCardProps) {
    const { detention, trip, completed } = props;
    const [isMessageOpen, setIsMessageOpen] = React.useState({
        PreDetention: false,
        InDetention: false,
    });

    const getTitle = () => {
        if (detention?.is_loss) {
            if (detention?.loss_detail?.status === "PRE_LOSS") {
                return "PRE-LOSS"
            } else if (detention?.loss_detail?.status === "POST_LOSS") {
                return "LOSS";
            } else if (detention?.loss_detail?.status === "IN_LOSS") {
                return "IN-LOSS";
            } else {
                return "LOSS";
            }
        } else {
            if (detention?.detention_detail?.status === "PRE_DETENTION") {
                return "PRE-DETENTION";
            } else if (detention?.detention_detail?.status === "POST_DETENTION") {
                return "DETENTION";
            } else if (detention?.detention_detail?.status === "IN_DETENTION") {
                return "IN-DETENTION";
            } else {
                return "DETENTION";
            }
        }
    }

    const getTitleColor = () => {
        // Pre detention #918D26
        // In detention #4E803D
        if (detention?.is_loss) {
            if (detention?.loss_detail?.status === "PRE_LOSS") {
                return "bg-[#918D26]"
            } else if (detention?.loss_detail?.status === "POST_LOSS") {
                return "bg-[#9A8660]";
            } else if (detention?.loss_detail?.status === "IN_LOSS") {
                return "bg-[#4E803D]";
            } else {
                return "bg-[#9A8660]";
            }
        } else {
            if (detention?.detention_detail?.status === "PRE_DETENTION") {
                return "bg-[#918D26]";
            } else if (detention?.detention_detail?.status === "POST_DETENTION") {
                return "bg-[#4D7F9B]";
            } else if (detention?.detention_detail?.status === "IN_DETENTION") {
                return "bg-[#4E803D]";
            } else {
                return "bg-[#4D7F9B]";
            }
        }
    }

    const hideCard = () => {
        if (detention?.is_loss && detention?.loss_detail?.status === "NOT_LOSS") {
            return true;
        }
        if (!detention?.is_loss && detention?.detention_detail?.status === "NOT_DETENTION") {
            return true;
        }
        return false;
    }

    const getTimeRemainingTillChargeStarts = (): number => {
        if (detention?.is_loss) {
            return detention?.loss_detail?.time_remaining_charge_start_time?.value || 0;
        }
        return detention?.detention_detail?.time_remaining_charge_start_time?.value || 0;
    }

    const getTimeDuration = (): number => {
        if (detention?.is_loss) {
            return detention?.loss_detail?.elapsed_time_since_start_time?.value || 0;
        }
        return detention?.detention_detail?.elapsed_time_since_start_time?.value || 0;
    }

    const title = getTitle();

    return (
        <div className={`flex flex-col w-full ${hideCard() ? "invisible" : "bg-gray-50"} p-4 rounded gap-6 min-h-[260px]`}>
            <div className="flex justify-between items-center">
                <span className={`py-0.5 px-2 text-white 
                    ${getTitleColor()}
                    text-sm font-semibold`}>{title}
                </span>
                {((title === "PRE-DETENTION" || title === "PRE-LOSS") && !completed) && <span
                    className="text-[#1A00E2] font-semibold inline-flex items-center cursor-pointer"
                    onClick={() => setIsMessageOpen({ ...isMessageOpen, PreDetention: true })}
                >
                    {title === "PRE-DETENTION" ? "Pre-Detention" : "Pre-Loss"} Message <EnvelopeIcon className="h-6 w-6 ml-2" />
                </span>}
                {((title === "IN-DETENTION" || title === "IN-LOSS") && !completed) && <span
                    className="text-[#1A00E2] font-semibold inline-flex items-center cursor-pointer"
                    onClick={() => setIsMessageOpen({ ...isMessageOpen, InDetention: true })}
                >
                    {title === "IN-DETENTION" ? "In-Detention" : "In-Loss"} Message <EnvelopeIcon className="h-6 w-6 ml-2" />
                </span>}
            </div>
            <div className="flex flex-row">
                <div className={`flex flex-col gap-4 ${title.startsWith("PRE") ? "" : "basis-[50%]"}`}>
                    <div>
                        <span className="text-[#606479]">Arrival:</span>
                        <span className="text-[#1C244A]"> {formatDateTimeString(detention?.arrival_datetime?.utc_datetime, detention?.arrival_datetime?.offset_minutes, "Not Available")}</span>
                    </div>
                    {(!title.startsWith("PRE") && !title.startsWith("IN")) && <div>
                        <span className="text-[#606479]">Departed:</span>
                        <span className="text-[#1C244A]"> {formatDateTimeString(detention?.departure_datetime?.utc_datetime, detention?.departure_datetime?.offset_minutes, "Not Available")}</span>
                    </div>}
                    {!title.startsWith("PRE") && <div>
                        <span className="text-[#606479]">Driver on Time:</span>
                        <span className="text-[#1C244A]"> {detention?.is_on_time ? "Yes" : "No"}</span>
                    </div>}
                    {(title.startsWith("PRE") && detention?.is_loss) && <div>
                        <span className="text-[#606479]">Time since arrival:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(getTimeDuration(), "0h")}</span>
                    </div>}
                    {(title.startsWith("PRE") && !detention?.is_loss) && <div>
                        <span className="text-[#606479]">Time since appointment:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(getTimeDuration(), "0h")}</span>
                    </div>}
                    {(title.startsWith("PRE") && !detention?.is_loss) && <div>
                        <span className="text-[#606479]">Time remaining till charge starts:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(getTimeRemainingTillChargeStarts(), "0h")}</span>
                    </div>}
                </div>
                {!title.startsWith("PRE") && <div className="flex flex-col gap-4 basis-[50%]">
                    {!detention?.is_loss && <div>
                        <span className="text-[#606479]">Detention Hours:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(detention?.detention_detail?.duration?.value)}</span>
                    </div>}
                    {!detention?.is_loss && <div>
                        <span className="text-[#606479]">Detention Rate:</span>
                        <span className="text-[#1C244A]"> {formatCurrency(detention?.detention_detail?.rate, "$0")}</span>
                    </div>}
                    {!detention?.is_loss && <div>
                        <span className="text-[#606479]">Detention Amount:</span>
                        <span className="text-[#1C244A]"> {formatCurrency(detention?.detention_detail?.total_amount, "$0")}</span>
                    </div>}
                    {detention?.is_loss && <div>
                        <span className="text-[#606479]">Late by:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(detention?.loss_detail?.late_duration_total?.value)}</span>
                    </div>}
                    {detention?.is_loss && <div>
                        <span className="text-[#606479]">Loss Hours:</span>
                        <span className="text-[#1C244A]"> {beautifyTime(detention?.loss_detail?.duration?.value)}</span>
                    </div>}
                    {detention?.is_loss && <div>
                        <span className="text-[#606479]">Loss Rate:</span>
                        <span className="text-[#1C244A]"> {formatCurrency(detention?.loss_detail?.rate, "$0")}</span>
                    </div>}
                    {detention?.is_loss && <div>
                        <span className="text-[#606479]">Loss Amount:</span>
                        <span className="text-[#1C244A]"> {formatCurrency(detention?.loss_detail?.total_amount, "$0")}</span>
                    </div>}
                </div>}
            </div>
            <PreDetentionMessage
                isOpen={isMessageOpen["PreDetention"]}
                setIsOpen={(isOpen) => setIsMessageOpen({ ...isMessageOpen, PreDetention: isOpen })}
                tripId={detention.trip_number}
                customerName={trip?.customer_name}
                orderNumber={trip?.load_num}
                stopData={(trip?.stops || []).find(stop => stop.id === detention.stop_id)}
                appointmentTime={formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                    time: true,
                    date: false,
                })}
                appointmentDate={formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                    time: false,
                    date: true,
                })}
                arrivalTime={formatDateTimeString(detention?.arrival_datetime?.utc_datetime, detention?.arrival_datetime?.offset_minutes, "Not Available", {
                    time: true,
                    date: false,
                })}
                chargeStartTime={detention?.is_loss ? detention?.loss_detail?.charge_start_time : detention?.detention_detail?.charge_start_time}
                startTime={detention?.is_loss ? detention?.loss_detail?.start_time : detention?.detention_detail?.start_time}
                modifiedTime={detention.modified_at}
                isLoss={detention?.is_loss}
                elapsed_time_since_start_time={beautifyTime(getTimeDuration(), "0h", {hourUnit:"hours", minuteUnit:"mins"})}
                time_remaining_charge_start_time={beautifyTime(getTimeRemainingTillChargeStarts(), "0h", {hourUnit:"hours", minuteUnit:"mins"})}
            />
            <InDetentionMessage
                isOpen={isMessageOpen["InDetention"]}
                setIsOpen={(isOpen) => setIsMessageOpen({ ...isMessageOpen, InDetention: isOpen })}
                tripId={detention.trip_number}
                customerName={trip?.customer_name}
                orderNumber={trip?.load_num}
                stopData={(trip?.stops || []).find(stop => stop.id === detention.stop_id)}
                appointmentTime={formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                    time: true,
                    date: false,
                })}
                appointmentDate={formatDateTimeString(detention?.appointment_datetime?.utc_datetime, detention?.appointment_datetime?.offset_minutes, "Not Available", {
                    time: false,
                    date: true,
                })}
                arrivalTime={formatDateTimeString(detention?.arrival_datetime?.utc_datetime, detention?.arrival_datetime?.offset_minutes, "Not Available", {
                    time: true,
                    date: false,
                })}
                chargeStartTime={detention?.is_loss ? detention?.loss_detail?.charge_start_time : detention?.detention_detail?.charge_start_time}
                startTime={detention?.is_loss ? detention?.loss_detail?.start_time : detention?.detention_detail?.start_time}
                modifiedTime={detention.modified_at}
                arrivalTS={detention?.arrival_datetime?.utc_datetime}
                isLoss={detention?.is_loss}
                elapsed_time_since_start_time={beautifyTime(getTimeDuration(), "0 min", {hourUnit:"hours", minuteUnit:"mins"})}
            />
        </div>
    );
}