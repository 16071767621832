import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorhandling";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { fetchTasks } from "../../redux/slice/manualTask";
import { getFilteredTasks } from "../../utils/taskutils";

const ManualTasks = () => {
    const { tasks } = useAppSelector((state) => state.manualTasks);
    const { loading } = useAppSelector((state) => state.availableTrucks);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const links = [];

    useEffect(() => {
        dispatch(fetchTasks()).then((actionResult) => {
            if (actionResult.type === "availableTrucksData/availableTrucksData/rejected") {
                handleError(actionResult.payload, toast, actionResult.payload);
            }
        });
    }, [dispatch]);

    return loading ? (
        <Loading />
    ) : (
        <Card
            image={<img src={require("../../../assets/images/exceptionCards/manualTasks.png")} alt="Custom_exception" className="h-5" />}
            title="Tasks"
        >
            <div className="flex flex-col">
                <div className="text-gray-700 flex flex-row gap-4">
                    <div>Create New Task</div>
                    <div>
                        <button className="w-6 h-6 bg-blue-700 text-white rounded-md flex items-center justify-center" onClick={() => {
                            navigate("/task/create");
                        }}>
                            <PlusIcon className="h-6 w-6" strokeWidth={10} />
                        </button>
                    </div>
                </div>
                <p className="mb-2 mt-2">
                    <span
                        onClick={() => {
                            navigate("/task/list");
                        }}
                        className="ml-3 text-blue-600 cursor-pointer border-b border-blue-600"
                    >
                        Open Tasks ({getFilteredTasks(tasks, "OPEN").length})
                    </span>
                </p>
                <p className="mb-2">
                    <span
                        onClick={() => {
                            navigate("/task/list?status=PAST_DUE");
                        }}
                        className="ml-3 text-blue-600 cursor-pointer border-b border-blue-600"
                    >
                        Past Due Tasks ({getFilteredTasks(tasks, "PAST_DUE").length})
                    </span>
                </p>
                <p className="mb-2">
                    <span
                        onClick={() => {
                            navigate("/task/list?status=RESOLVED");
                        }}
                        className="ml-3 text-blue-600 cursor-pointer border-b border-blue-600"
                    >
                        Resolved Tasks ({getFilteredTasks(tasks, "RESOLVED").length})
                    </span>
                </p>
                <p className="mb-2">
                    <span
                        onClick={() => {
                            navigate("/task/list?status=DUE_TODAY");
                        }}
                        className="ml-3 text-blue-600 cursor-pointer border-b border-blue-600"
                    >
                        Due Today ({getFilteredTasks(tasks, "DUE_TODAY").length})
                    </span>
                </p>
            </div>

        </Card>
    );
};

export default ManualTasks;
