import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import HomeLayout from "../../../components/Layout/homeLayout";
import TaskViewComponent from "../../../components/ManualTask/TaskViewComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchTasks } from "../../../redux/slice/manualTask";

export default function TaskView() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const navigate = useNavigate();
    const { tasks } = useAppSelector((state) => state.manualTasks);
    useEffect(() => {
        if (!tasks?.length) {
            dispatch(fetchTasks());
        } else {
            if (!id) {
                navigate("/task/list");
            }
            const task = tasks.find((task) => task._id === id);
            if (!task) {
                navigate("/task/list");
            }
        }
    }), [tasks, id];
    return (
        <HomeLayout>
            <TaskViewComponent task={tasks.find((task) => task._id === id)} />
        </HomeLayout>
    );
}