import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// This hook is used to check if the user is authenticated or not.
// If the user is not authenticated, it will redirect the user to the login page.
export default function useAuthGuard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);
}
