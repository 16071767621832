import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { withAuthHeader } from "../../utils/api";

export const autocomplete = createAsyncThunk(
  "orderNumber/autocomplete",
  async (
    params: {
      search?: string;
      limit?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER +
          `/v1/loads/order_number/autocomplete?search=${params.search}&limit=${params.limit}`,
        {
          mode: "cors",
          method: "GET",
          headers: await withAuthHeader({
            Accept: "application/json",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data?.Data || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for the data with reducers to handle the actions
export const orderNumberSlice = createSlice({
  name: "orderNumberData",
  initialState: {
    orderNumber: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(autocomplete.pending, (state) => {
        state.loading = true;
      })
      .addCase(autocomplete.fulfilled, (state, action) => {
        state.loading = false;
        state.orderNumber = action.payload;
      })
      .addCase(autocomplete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
