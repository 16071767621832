import React from "react";
import Modal from 'react-modal';
import { displayTimeDetails, displayShortDate } from "../../utils/dateutils";

Modal.setAppElement('#root'); // Set the app element early

export default function ShiftHoursModal(props) {
    const closeModel = () => {
        props.setshowModel(false)
    }

    return (
        <Modal
            className="modal content-center"
            overlayClassName="overlay"
            isOpen={props.showModel}>
            <div className="bg-slate-50 content-center w-3/5 h-3/5">
                <div className="grid justify-items-end">
                <button className="close-button" onClick={closeModel}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div>
                <table className="min-w-full text-center table-auto text-white">
                    <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
                    <tr className="shadow-md">
                    <th scope="col" className="px-6 py-4 header-cell day-column">Day in Cycle</th>
                    <th scope="col" className="px-6 py-4 header-cell daily-hours-column">Day</th>
                    <th scope="col" className="px-6 py-4 header-cell date-column">Date</th>
                    <th scope="col" className="px-6 py-4 header-cell shift-hours-column">Shift hours</th>
                    <th scope="col" className="px-6 py-4 header-cell hours-used-column">Hours used</th>
                    <th scope="col" className="px-6 py-4 header-cell hours-remaining-column">Hours remaining</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.currentData?.map((delivery, index) => 
                    delivery?.driver?.hos_calc_remaining && 
                    (delivery?.driver?.hos_calc_remaining[props.day]?.day_calc_details?.map(
                    (details, cycleIndex) => (
                        <tr key={cycleIndex} className="last:hover:bg-sky-200 last:bg-sky-100 last:font-semibold border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 text-gray-900">
                        <td className="px-6 py-4 data-cell day-column">{details?.day}</td>
                        <td className="px-6 py-4 data-cell daily-hours-column">{ details?.day_description } </td>
                        <td className="px-6 py-4 data-cell date-column">
                            { displayShortDate(details?.date) }
                        </td>
                        <td className="px-6 py-4 data-cell shift-hours-column">{displayTimeDetails(details?.shift_hours)}</td>
                        <td className="px-6 py-4 data-cell hours-used-column">{displayTimeDetails(details?.hours_used)}</td>
                        <td className="px-6 py-4 data-cell hours-remaining-column">{displayTimeDetails(details?.hours_remaining)}</td>
                        </tr>
                    ))))}
                    </tbody>
                </table>
                </div>
            </div>
        </Modal>
    )
}