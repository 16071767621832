import React, { useState, useEffect, FormEvent } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import useAuthGuard from "../../hooks/useAuthGuard";
import { auth, updateCurrentProfile } from "../../firebase/config";
import { toast } from "react-toastify";
import { User } from "firebase/auth";

export default function UserProfile() {
    useAuthGuard();
    const [currUser, setCurrUser] = useState<User>(auth?.currentUser || {} as User);

    auth.onAuthStateChanged((user) => {
        if (user != currUser) {
            setCurrUser(user);
        }
    });
    const [displayName, setDisplayName] = useState(currUser?.displayName || "");
    const [email, setEmail] = useState(currUser?.email || "");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currUser) {
            setDisplayName(currUser?.displayName || "");
            setEmail(currUser?.email || "");
        }
    }, [currUser]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (auth.currentUser) {
                await updateCurrentProfile({ displayName });
                toast.success("Profile updated successfully", {
                    autoClose: 700,
                    pauseOnHover: false,
                });
            }
        } catch (error) {
            toast.error("Failed to update profile");
        } finally {
            setLoading(false);
        }
    };

    return (
        <HomeLayout>
            <div className="container flex flex-col items-center justify-center">
                <div className="w-full max-w-md">
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Edit Profile
                    </h2>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="displayName" className="block text-sm font-medium leading-6 text-gray-900">
                                Display Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="displayName"
                                    name="displayName"
                                    type="text"
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    className="indent-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    disabled
                                    className="indent-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </HomeLayout>
    );
}