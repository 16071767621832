import { ArrowRightIcon, DocumentTextIcon } from "@heroicons/react/24/outline"
import ExceptionNotesSection from "../ExceptionNotesSection/exceptionnotessection";
import { getDateFromDatePicker } from "../../utils/dateutils";
import { useEffect, useState } from "react";
import { getNotes } from "../../redux/slice/notes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function TaskViewComponent({ task }: { task?: any }) {
    const navigate = useNavigate();
    const [notes, setNotes] = useState<any[]>([]);

    const getCreatedFromLink = () => {
        if (task?.origin?.meta_data) {
            if (task?.origin?.meta_data?.created_from_id && task?.origin?.meta_data?.created_from_type) {
                switch (task?.origin?.meta_data?.created_from_type) {
                    case "eta":
                        return `/exceptionDetails?id=${task?.origin?.meta_data?.created_from_id}&module=eta`;
                    case "detention":
                        return `/exceptionDetails?id=${task?.origin?.meta_data?.created_from_id}&module=detention`;
                    case "tempViolation":
                        return `/exceptionDetails?id=${task?.origin?.meta_data?.created_from_id}&module=tempViolation`;
                }
            }
        }
    };

    const reloadNotes = () => {
        getNotes(task.task_id, "MANUAL_TASK", toast).then((data) => {
            setNotes(data);
        });
    }

    useEffect(() => {
        if (task?.task_id) {
            reloadNotes();
        }
    }, []);

    if (!task?.task_id) {
        return null;
    }

    if (!task) {
        return null;
    }

    console.log(task);
    return (
        <div className="flex flex-col">
            <p className="text-2xl font-bold text-gray-700 my-2">
                Task Details
            </p>
            <hr className="mb-6 border-gray-400" />
            <div className="flex flex-row">
                <div className="flex flex-row basis-[50%] justify-start gap-12">
                    <div className="flex flex-row gap-3">
                        <span className="text-[#4F5B67] font-bold">Task Type:</span>
                        <span className="text-[#1C244A]">{task?.task_type?.name}</span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <span className="text-[#4F5B67] font-bold">Department:</span>
                        <span className="text-[#1C244A]">{task?.department_details?.name}</span>
                    </div>
                </div>
                <div className="flex flex-row basis-[50%] justify-end gap-8">
                    <div>
                        <div
                            className="bg-[#2F884D] rounded-sm inline-flex w-max px-1.5 pt-[2px] pb-[2px] gap-2 text-white text-xs font-semibold uppercase"
                        >
                            {task?.status}
                        </div>
                    </div>
                    {(task?.origin?.meta_data?.created_from_type && task?.origin?.meta_data?.created_from_id) && <div>
                        <div
                            className="bg-[#1A00E2] cursor-pointer rounded-sm inline-flex w-max px-1.5 pt-[2px] pb-[2px] gap-2 text-white text-xs font-semibold uppercase"
                            onClick={() => {
                                navigate(getCreatedFromLink());
                            }}
                        >
                            Created From <ArrowRightIcon className="h-4 w-4 m-auto" />
                        </div>
                    </div>}
                </div>
            </div>
            <p className="text-lg font-bold text-[#4F5B67] mt-6 mb-1">
                RELATED TO
            </p>
            <div className="p-4 rounded-md bg-gray-50 w-full flex flex-col">
                <div className="w-full h-full flex flex-row justify-start mb-4">
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Trip Number:</span>
                        <span className="text-[#1C244A] text-sm">{task?.trip_id}</span>
                    </div>
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Order Number:</span>
                        <span className="text-[#1C244A] text-sm">{task?.order_id}</span>
                    </div>
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Customer Name:</span>
                        <span className="text-[#1C244A] text-sm">{task?.customer_name}</span>
                    </div>
                </div>
                <div className="w-full h-full flex flex-row justify-start">
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Truck Number:</span>
                        <span className="text-[#1C244A] text-sm">{task?.truck_id}</span>
                    </div>
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Trailer Number:</span>
                        <span className="text-[#1C244A] text-sm">{task?.trailer_id}</span>
                    </div>
                    <div className="basis-1/3 inline-flex gap-3 justify-start">
                        <span className="text-[#4F5B67] text-sm font-bold">Driver Name:</span>
                        <span className="text-[#1C244A] text-sm">{task?.driver_details?.name}</span>
                    </div>
                </div>
            </div>
            <p className="text-lg font-bold text-[#4F5B67] mt-6 mb-1">
                ISSUE
            </p>
            <div className="p-4 rounded-md bg-gray-50 w-full flex flex-col gap-8">
                <div className="w-full h-full flex flex-row mb-4 justify-stretch">
                    <div className="basis-1/3">
                        <div className="rounded-md inline-flex gap-3 justify-start bg-[#7A7CC0] py-1 px-8">
                            <span className="text-[#D6DDE3] text-sm uppercase font-bold">Due Date:</span>
                            <span className="text-white text-sm">{task?.due_date ? getDateFromDatePicker(new Date(task?.due_date)) : ""}</span>
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <div className="inline-flex gap-3 justify-start py-1 px-8">
                            <span className="text-[#4F5B67] text-sm font-bold">Created By:</span>
                            <span className="text-[#1C244A] text-sm">{task?.created_by?.name}</span>
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <div className="inline-flex gap-3 justify-start py-1 px-8">
                            <span className="text-[#4F5B67] text-sm font-bold">Assigned To:</span>
                            <span className="text-[#1C244A] text-sm">{task?.assigned_to?.name}</span>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full text-sm flex flex-col gap-2">
                    <span className="text-[#4F5B67] font-bold">Issue Description:</span>
                    <span className="text-[#1C244A] text-sm">
                        {task?.issue?.description}
                    </span>
                </div>
                <div className="w-full h-full text-sm flex flex-col gap-2">
                    <span className="text-[#4F5B67] font-bold">Issue Instructions:</span>
                    <span className="text-[#1C244A] text-sm">
                        {task?.issue?.instruction}
                    </span>
                </div>
            </div>
            <ExceptionNotesSection originId={task.task_id} originType="MANUAL_TASK" notesUpdated={reloadNotes} />
        </div>
    );
}