import { TaskStatus } from "../types";

export const getFilteredTasks = (tasks: any[], status: TaskStatus, assignedTo?: string[]) => {
    let data = filterByStatus(status, tasks);
    if (assignedTo?.length) {
        data = data?.filter((task) => assignedTo.includes(task.assigned_to.user_id));
    }
    return data;
}

function filterByStatus(status: string, tasks: any[]) {
    switch (status) {
        case "OPEN":
            return tasks?.filter((task) => task.status === "OPEN");
        case "RESOLVED":
            return tasks?.filter((task) => task.status === "RESOLVED");
        case "PAST_DUE":
            return tasks?.filter((task) => new Date(task.due_date) < new Date());
        case "DUE_TODAY":
            return tasks?.filter((task) => new Date(task.due_date).toDateString() === new Date().toDateString());
        default:
            return tasks;
    }
}
