type Trip = {
    _id: string;
    client_id: string;
    truck_id: string;
    trip_number: string;
    driver?: {
      driver_id?: string;
      name?: string;
      phone?: string;
      address?: {
        name?: string;
        street?: string;
        city?: string;
        state?: string;
        country?: string;
        coordinates?: {
          latitude: number;
          longitude: number;
        };
      };
    };
    last_stop?: {
      name: string;
      address: {
        street: string;
        city: string;
        state: string;
        zip_code: string;
        coordinates: {
          latitude: number;
          longitude: number;
        };
      };
      id: string;
      datetime: string;
      timezone_offset: number;
      customer_name: string;
    };
    event_details?: {
      start_date_time: string;
      end_date_time: string;
      title: string;
      description: string;
    };
  };
  
  // Function to bucket data
  export function bucketDataAvailableTrucks(data: Trip[]) {
    const UNASSIGNED: Trip[] = [];
    const PAST: Trip[] = [];
    const TODAY: Trip[] = [];
    const TOMORROW: Trip[] = [];
  
    const now = new Date();
    const todayStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())); // Start of today in UTC
    const tomorrowStart = new Date(todayStart);
    tomorrowStart.setUTCDate(todayStart.getUTCDate() + 1); // Start of tomorrow in UTC
    const tomorrowEnd = new Date(todayStart);
    tomorrowEnd.setUTCDate(todayStart.getUTCDate() + 2); // Start of the day after tomorrow in UTC
  
  
    data.forEach((trip) => {
      // If there's no driver assigned
      if (!trip.driver || !trip.driver.name) {
        UNASSIGNED.push(trip);
        return;
      }
  
      const lastStopDatetime = trip.last_stop ? new Date(trip.last_stop.datetime) : null;
  
      if (lastStopDatetime) {
        if (lastStopDatetime < todayStart) {
          PAST.push(trip); // Trips that are in the past
        } else if (lastStopDatetime >= todayStart && lastStopDatetime < tomorrowStart) {
          TODAY.push(trip); // Trips scheduled for today
        } else if (lastStopDatetime >= tomorrowStart) {
          TOMORROW.push(trip); // Trips scheduled for tomorrow
        }
      } else {
        // If there's no date information, consider it unassigned
        UNASSIGNED.push(trip);
      }
    });
  
    return { UNASSIGNED, PAST, TODAY, TOMORROW };
  }
  