import React from "react";
import { translateDutyStatus } from "../../utils/stringmappings";
import { displayTimeDetails } from "../../utils/dateutils";

export default function ShiftStatusCard(props) { 
    return (
        <div className="sm:w-1/2 w-full p-4 rounded bg-gray-50">
            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9]">
            <div className="text-[#4F5B67]">Duty Status:</div>
            <div className="text-[#1C244A]">
                {translateDutyStatus(props.legalTimeStatus?.duty_status)}
            </div>
            </div>

            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9] ">
            <div className="text-[#4F5B67]">Time Until Break:</div>
            <div className="text-[#1C244A]">
                {displayTimeDetails(props.legalTimeStatus?.time_until_break)}
            </div>
            </div>
            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9] ">
            <div className="text-[#4F5B67]">Drive Remaining:</div>
            <div className="text-[#1C244A]">
                {displayTimeDetails(props.legalTimeStatus?.drive_remaining)}
            </div>
            </div>
            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9]">
            <div className="text-[#4F5B67]">Cycle Remaining:</div>
            <div className="text-[#1C244A]">
                {displayTimeDetails(props.legalTimeStatus?.cycle_remaining)}
            </div>
            </div>
            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9] ">
            <div className="text-[#4F5B67]">Shift Remaining</div>
            <div className="text-[#1C244A]">
                {displayTimeDetails(props.legalTimeStatus?.shift_remaining)}
            </div>
            </div>
            <div className="flex pb-3 text-sm justify-between border-b-2 border-[#A8B0B9] ">
            <div className="text-[#4F5B67]">
                Days Since Last 34 Hour Reset:
            </div>
            <div className="text-[#1C244A]">
                {props.legalTimeStatus?.days_since_last_reset}
            </div>
            </div>
        </div>
    )
}