"use client";
import { useEffect, useState, useRef } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getFilteredData, TripFilter } from "../../utils/triputils";
import useAuthGuard from "../../hooks/useAuthGuard";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/currency";
import { DetentionStatus, ETAStatus, ExceptionType, TemperatureComplianceStatus, TripStatus, TripType } from "../../types";
import { handleError } from "../../utils/errorhandling";
import DatePicker from "../../components/Dropdown/datePicker";
import ExceptionStatusDropdown from "../../components/Dropdown/exceptionStatusDropdown";
import FilledButton from "../../components/BlockButton/UpdateFilter";
import OutlinedButton from "../../components/BlockButton/ClearFilter";
import { setClickIndex } from "../../redux/reducer";
import { fetchCompletedData, fetchData } from "../../redux/slice/data";
import { wait } from "../../utils/sleep";

const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

const ExceptionList: React.FC = () => {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, completedData, loading } = useAppSelector((state) => state.data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<TripFilter>({
    ...safeJSONParse(queryParams.get("filters")) || {},
  });
  const [newFilters, setNewFilters] = useState<TripFilter>(filters);
  const [searchText, setSearchText] = useState<string>("");
  const [clearLoading, setClearLoading] = useState(false);
  const module = filters.module || ExceptionType.ETA;

  const clearFilter = async () => {
    setClearLoading(true);
    setFilters({
      module,
      tripType: filters.tripType,
    });
    setNewFilters({
      module,
      tripType: filters.tripType,
    });
    navigate(`/exceptionList?filters=${JSON.stringify({
      module,
      tripType: filters.tripType,
    })}`);
    await wait(100);
    setClearLoading(false);
  }

  const checkModule = (moduleToCheck) => {
    return module === moduleToCheck;
  }

  const exceptionDropdowns = [
    {
      check: () => checkModule(ExceptionType.ETA) || checkModule(ExceptionType.TEMP_VIOLATION) || checkModule(ExceptionType.DETENTION),
      options: [{
        label: "Dispatched",
        value: TripStatus.Dispatched
      }, {
        label: "In Transit",
        value: TripStatus.InTransit
      }, {
        label: "Completed",
        value: TripStatus.Completed
      }, {
        label: "All",
        value: TripStatus.All
      }],
      key: "Trip Status",
      name: "Trip Status",
      multiselect: true,
      selectedOptions: filters.tripStatus || [],
      onChange: (selectedOptions) => {
        setNewFilters(prev => {
          return {
            ...prev,
            tripStatus: selectedOptions
          }
        });
      },
    },
    {
      check: () => checkModule(ExceptionType.ETA),
      options: [{
        label: "On TIme",
        value: ETAStatus.OnTime
      }, {
        label: "Late",
        value: ETAStatus.Late
      }, {
        label: "On Time, Previously Late",
        value: ETAStatus.OnTimePreviouslyLate
      }, {
        label: "All",
        value: ETAStatus.All
      }],
      selectedOptions: filters.etaStatus || [],
      key: "ETA Status",
      name: "ETA Status",
      multiselect: true,
      onChange: (selectedOptions) => {
        setNewFilters(prev => {
          return {
            ...prev,
            etaStatus: selectedOptions
          }
        });
      },
    },
    // {
    //   check: () => checkModule(ExceptionType.ETA) || checkModule(ExceptionType.TEMP_VIOLATION) || checkModule(ExceptionType.DETENTION),
    //   options: [{
    //     label: "Open",
    //     value: ExceptionStatus.Open
    //   }, {
    //     label: "In Progress",
    //     value: ExceptionStatus.InProgress
    //   }, {
    //     label: "Closed",
    //     value: ExceptionStatus.Closed
    //   }, {
    //     label: "All",
    //     value: ExceptionStatus.All
    //   }],
    //   key: "Exception Status",
    //   name: "Exception Status",
    //   multiselect: true,
    //   selectedOptions: filters.exceptionStatus || [],
    //   onChange: (selectedOptions) => {
    //     setNewFilters(prev => {
    //       return {
    //         ...prev,
    //         exceptionStatus: selectedOptions
    //       }
    //     });
    //   },
    // },
    {
      check: () => checkModule(ExceptionType.TEMP_VIOLATION),
      options: [{
        label: "Compliant",
        value: TemperatureComplianceStatus.Compliant
      }, {
        label: "In Violation",
        value: TemperatureComplianceStatus.InViolation
      }, {
        label: "Compliant, Previously In Violation",
        value: TemperatureComplianceStatus.CompliantPreviouslyInViolation
      }, {
        label: "All",
        value: TemperatureComplianceStatus.All
      }],
      selectedOptions: filters.tempStatus || [],
      key: "Compliance Status",
      name: "Compliance Status",
      multiselect: true,
      onChange: (selectedOptions) => {
        setNewFilters(prev => {
          return {
            ...prev,
            tempStatus: selectedOptions
          }
        });
      },
    },
    {
      check: () => checkModule(ExceptionType.DETENTION),
      options: [{
        label: "Pre-Detention",
        value: DetentionStatus.PreDetention
      }, {
        label: "In-Detention",
        value: DetentionStatus.InDetention
      }, {
        label: "Completed Trip with Detention",
        value: DetentionStatus.CompletedTripWithDetention
      }, {
        label: "Completed Trip with Loss",
        value: DetentionStatus.CompletedTripWithLoss
      }, {
        label: "All",
        value: DetentionStatus.All
      }],
      selectedOptions: filters.detentionStatus || [],
      key: "Detention Status",
      name: "Detention Status",
      multiselect: true,
      onChange: (selectedOptions) => {
        setNewFilters(prev => {
          return {
            ...prev,
            detentionStatus: selectedOptions
          }
        });
      },
    },
  ];

  // Download data from an API and store it in the state
  useEffect(() => {
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
    dispatch(fetchCompletedData()).then((actionResult) => {
      if (actionResult.type === "data/fetchCompletedData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
  }, [dispatch]);

  const renderTable = (searchText: string, setSearchText: (text: string) => void, applySearch: () => void) => {
    return (
      <div className="flex flex-col overflow-x-auto w-full">
        <table
          className="whitespace-nowrap overflow-x-auto 
          min-w-full text-sm text-left rtl:text-right text-white"
        >
          <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
            <tr className="shadow-md">
              <th colSpan={8} className="rounded-tl-lg rounded-tr-lg px-6 py-3">
                <div className="flex gap-8 items-center">
                  <input
                    type="text"
                    placeholder="Search by Trip #"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    className="border border-gray-300 px-4 py-1 text-sm rounded-full w-60 placeholder:font-light text-black outline-none"
                  />
                  <button className="border border-[#7A7CC0] bg-[#7A7CC0] text-white px-4 py-1 text-sm font-normal rounded-full min-w-24" onClick={applySearch}>
                    Search
                  </button>
                </div>
              </th>
            </tr>
            <tr className="shadow-md">
              <th scope="col" className="rounded-bl-lg px-6 py-3">
                Trip #
              </th>
              <th scope="col" className="px-6 py-3">
                Truck #
              </th>
              <th scope="col" className="px-6 py-3">
                Trailer #
              </th>
              <th scope="col" className="px-6 py-3">
                Driver Name
              </th>
              <th scope="col" className="px-6 py-3">
                Client Name
              </th>
              <th scope="col" className={`px-6 py-3 ${checkModule(ExceptionType.DETENTION) ? "" : "rounded-br-lg"}`}>
                Order #:
              </th>
              {checkModule(ExceptionType.DETENTION) && <th scope="col" className="px-6 py-3">
                Detention Amount
              </th>}
              {checkModule(ExceptionType.DETENTION) && <th scope="col" className="px-6 py-3 rounded-br-lg">
                Loss Amount
              </th>}
            </tr>
          </thead>
          <tbody>
            <tr
              className="rounded-lg bg-tableData-bg border-b
              text-gray-900 font-semibold"
            >
              <td colSpan={8} className="px-6 py-3"></td>
            </tr>
            {getFilteredData(data, completedData, filters)?.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => handleClick(item.trip_num)}
                  key={item.id}
                  className="shadow-md bg-white
                  text-gray-900 font-semibold hover:cursor-pointer"
                >
                  <td className="px-6 py-3 rounded-tl-lg rounded-bl-lg">
                    {item.trip_num}
                  </td>
                  <td className="px-6 py-3">{item.truck_num}</td>
                  <td className="px-6 py-3">{item.trailer_num}</td>
                  <td className="px-6 py-3 break-words text-wrap">{item.driver.driver_name}</td>
                  <td className="px-6 py-3 break-words text-wrap">{item.customer_name}</td>
                  <td className="px-6 py-3">{item.load_num}</td>
                  {checkModule(ExceptionType.DETENTION) && <td className="px-6 py-3">{formatCurrency(item?.detention_summary?.detention_total_amount || 0)}</td>}
                  {checkModule(ExceptionType.DETENTION) && <td className="px-6 py-3">{formatCurrency(item?.detention_summary?.loss_total_amount || 0)}</td>}
                </tr>
                <tr>
                  <td colSpan={8} className="px-6 py-1"></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const [activeTab, setActiveTab] = useState<TripType>((filters.tripType || "internal") as TripType);
  const handleTabClick = (tabId: TripType) => {
    setActiveTab(tabId);
    setFilters({...newFilters, tripType: tabId});
    setNewFilters({...newFilters, tripType: tabId});
    navigate(`/exceptionList?filters=${JSON.stringify({
      ...filters,
      tripType: tabId
    })}`);
  };

  const handleClick = (trip_num) => {
    dispatch(setClickIndex(trip_num));
    navigate(`/exceptionDetails?id=${trip_num}&module=${module}${filters.completed ? "&completed=true" : ""}`);
  };

  const getTitle = () => {
    switch (module) {
      case ExceptionType.ETA:
        return "Trip ETA";
      case ExceptionType.TEMP_VIOLATION:
        return "Temperature Monitoring";
      case ExceptionType.DETENTION:
        return "Detention";
      default:
        return "Trip ETA";
    }
  }

  const Map = (props) => {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);
    const { apikey } = props;
  };

  if (loading || clearLoading) {
    return (
      <HomeLayout>
        <Loading />
      </HomeLayout>
    );
  }

  function applyFilters(): void {
    setFilters(newFilters);
    navigate(`/exceptionList?filters=${JSON.stringify(newFilters)}`);
  }

  return (
    <HomeLayout>
      <div className="sm:px-11 sm:mt-4">
        <div
          className="text-xl mb-2 text-titleColor font-semibold"
        >
          {getTitle()}
        </div>

        <div className="mb-4 flex flex-row gap-10">
          <div className="flex flex-row">
            <ul
              className="w-fit border-b-2 border-[#A8B0B9] 
                  flex flex-wrap -mb-px text-sm font-medium text-center"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
            >
              <li className="me-6 pl-3 mb-[-1px]" role="presentation">
                <button
                  className={`inline-block py-2 rounded-t-lg 
                ${activeTab === "internal"
                      ? "border-activeTabHeadingsBorder text-activeTabHeadingsColor border-b-[3.5px]"
                      : "text-tabHeadingsColor"
                    }`}
                  onClick={() => handleTabClick("internal")}
                >
                  Company Driver
                </button>
              </li>

              <li className="me-6 mb-[-1px]" role="presentation">
                <button
                  className={`inline-block py-2 rounded-t-lg 
                      ${activeTab === "external"
                      ? "border-activeTabHeadingsBorder text-activeTabHeadingsColor border-b-[3.5px]"
                      : "text-tabHeadingsColor"
                    }`}
                  onClick={() => handleTabClick("external")}
                >
                  External Driver
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-row justify-start align-middle my-6 gap-10">
          {exceptionDropdowns.map((dropdown, index) => (
            dropdown.check() && (
              <ExceptionStatusDropdown
                key={dropdown.key}
                options={dropdown.options}
                multiselect={dropdown.multiselect}
                onChange={dropdown.onChange}
                selectedOptions={dropdown.selectedOptions}
                name={dropdown.name}
              />
            )
          ))}
          {(checkModule(ExceptionType.ETA) || checkModule(ExceptionType.TEMP_VIOLATION) || checkModule(ExceptionType.DETENTION)) && (
            <DatePicker
              onChange={(from, to) => {
                setNewFilters(prev => {
                  return {
                    ...prev,
                    dateFilter: {
                      fromDate: from ? +new Date(from) : undefined,
                      toDate: to ? +new Date(to) : undefined
                    }
                  }
                });
              }}
              fromDate={filters?.dateFilter?.fromDate ? new Date(filters?.dateFilter?.fromDate) : undefined}
              toDate={filters?.dateFilter?.toDate ? new Date(filters?.dateFilter?.toDate) : undefined}
              placeholder=""
              value="Date Range"
            />
          )}
          <div className="inline-flex gap-8">
            <div className="align-middle my-auto">
              <FilledButton onClick={() => applyFilters()} text="Update Results" className="px-3" />
            </div>
            <div className="align-middle my-auto">
              <OutlinedButton onClick={() => { clearFilter() }} text="Clear Filters" className="px-3" />
            </div>
          </div>
        </div>

        <div id="default-tab-content">
          <div
            className={`${activeTab === "internal" ? "block" : "hidden"}`}
            role="tabpanel"
          >
            {renderTable(searchText, setSearchText, () => {
              setFilters({...newFilters, searchText});
            })}
          </div>
          <div
            className={`${activeTab !== "internal" ? "block" : "hidden"}`}
            role="tabpanel"
          >
            {renderTable(searchText, setSearchText, () => {
              setFilters({...newFilters, searchText});
            })}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default ExceptionList;
