import React from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import useAuthGuard from "../../hooks/useAuthGuard";
import ManualTasks from "../../components/ExceptionCards/ManualTasks";

export default function TasksHome() {
    useAuthGuard();

    return (
        <HomeLayout>
            <p className="text-2xl font-semibold text-gray-700 m-2">
                Tasks
            </p>
            <hr className="mb-4 border-gray-300" />
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="mx-auto flex justify-center mb-10">
                    <ManualTasks />
                </div>
            </div>
        </HomeLayout>
    );
}
