import React from "react";

export default function DriverCard(props) { 
    return (
        <div className="p-4 rounded bg-gray-50 w-full">
            <div className="sm:flex text-sm">
                <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                <span className="text-[#606479]">Driver:</span>
                <span className="text-[#1C244A]">
                    {" "}
                    {props.driverName}
                </span>
                </div>
                <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                <span className="text-[#606479]">Phone:</span>
                <span className="text-[#1C244A]">
                    {" "}
                    {props.contactPhone}
                </span>
                </div>
            </div>

            {!props.completed && <div className="sm:flex text-sm sm:mt-4">
                <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                <span className="text-[#606479]">Truck:</span>{" "}
                {props.truckNum}
                </div>
                <div className="sm:w-1/2 w-full sm:mb-0 mb-3">
                <span className="text-[#606479]">Trailer:</span>{" "}
                {props.trailerNum}
                </div>
            </div>}
        </div>
    )
}