import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData, fetchCompletedData } from "../../redux/slice/data";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorhandling";
import { DetentionStatus, ExceptionType } from "../../types";

const ReeferCard = () => {
  const { data, dataTS, loading, completedData, completedDataTS } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!(data.length > 0 && (dataTS + (10 * 60 * 1000)) > Date.now())) {
      dispatch(fetchData()).then((actionResult) => {
        if (actionResult.type === "data/fetchData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
    if (!(completedData.length > 0 && (completedDataTS + (10 * 60 * 1000)) > Date.now())) {
      dispatch(fetchCompletedData()).then((actionResult) => {
        if (actionResult.type === "data/fetchCompletedData/rejected") {
          handleError(actionResult.payload, toast, actionResult.payload);
        }
      });
    }
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      image={<img src={require("../../../assets/images/exceptionCards/detentionIcon.png")} alt="Detention" width={36} height={36} />}
      title="Detention Monitoring"
      skipCount={["Completed"]}
      tripData={{
        "Company Driver Trips": {
          "Pre-detention": getFilteredData(data, completedData, {
            tripType: "internal",
            detentionStatus: [DetentionStatus.PreDetention],
            module: ExceptionType.DETENTION
          }).length,
          "In-detention": getFilteredData(data, completedData, {
            tripType: "internal",
            detentionStatus: [DetentionStatus.InDetention],
            module: ExceptionType.DETENTION
          }).length,
          "Completed": getFilteredData(data, completedData, {
            tripType: "internal",
            completed: true,
            detentionStatus: [DetentionStatus.CompletedTripWithDetention, DetentionStatus.CompletedTripWithLoss],
            module: ExceptionType.DETENTION
          }).length,
        },
        "External Driver Trips": {
          "Pre-detention": getFilteredData(data, completedData, {
            tripType: "external",
            detentionStatus: [DetentionStatus.PreDetention],
            module: ExceptionType.DETENTION
          }).length,
          "In-detention": getFilteredData(data, completedData, {
            tripType: "external",
            detentionStatus: [DetentionStatus.InDetention],
            module: ExceptionType.DETENTION
          }).length,
          "Completed": getFilteredData(data, completedData, {
            tripType: "external",
            completed: true,
            detentionStatus: [DetentionStatus.CompletedTripWithDetention, DetentionStatus.CompletedTripWithLoss],
            module: ExceptionType.DETENTION
          }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          "Pre-detention": `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            detentionStatus: [DetentionStatus.PreDetention],
            module: "detention",
          })}`,
          "In-detention": `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            detentionStatus: [DetentionStatus.InDetention],
            module: "detention",
          })}`,
          Completed: `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            detentionStatus: [DetentionStatus.CompletedTripWithDetention, DetentionStatus.CompletedTripWithLoss],
            module: "detention",
            completed: true,
          })}`,
        },
        "External Driver Trips": {
          "Pre-detention": `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            detentionStatus: [DetentionStatus.PreDetention],
            module: "detention",
          })}`,
          "In-detention": `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            detentionStatus: [DetentionStatus.InDetention],
            module: "detention",
          })}`,
          Completed: `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            detentionStatus: [DetentionStatus.CompletedTripWithDetention, DetentionStatus.CompletedTripWithLoss],
            module: "detention",
            completed: true,
          })}`,
        },
      }}
    />
  );
};

export default ReeferCard;
