import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrorAction, withAuthHeader } from "../../utils/api";

export const fetchAvailableTrucksData = createAsyncThunk(
  "availableTrucksData/availableTrucksData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/available-trucks/all",
          {
            mode: "cors",
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data;
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const availableTrucksSlice = createSlice({
  name: "availableTrucksData",
  initialState: {
    availableTrucks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableTrucksData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailableTrucksData.fulfilled, (state, action) => {
        state.loading = false;
        state.availableTrucks = action.payload;
      })
      .addCase(fetchAvailableTrucksData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
