import { useLocation } from "react-router-dom";
import HomeLayout from "../../../components/Layout/homeLayout";
import CreateTaskComponent from "../../../components/ManualTask/CreateTaskComponent";
import { CreatedFromConfig } from "@/app/types";

export default function createNewTask() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const createdFromType = params.get("createdFromType");
    const createdFromId = params.get("createdFromId");
    return (
        <HomeLayout>
            <CreateTaskComponent taskConfig={(createdFromId && createdFromType) && {
                createdFromId,
                createdFromType
            } as CreatedFromConfig} />
        </HomeLayout>
    );
}